import React, { FC } from 'react'
import './BotaoGrande.css'
interface BotaoGrandeProps {
    onClick?: () => void
}
export const BotaoGrande: FC<BotaoGrandeProps> = (props) => {
    return (
        <div className="botaoGrande" onClick={props.onClick}>
            {props.children}
        </div>
    )
}
