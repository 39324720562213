import React, { createContext, useContext, useState, FC, useEffect, useCallback, useMemo } from "react";
import { useAuth } from "../auth/AuthContext";
import { useKiosk } from "../kiosk/KioskContext";
import { Catalog } from "../../../domains/catalog/aggregates/Catalog";
import { ProdutoTipo } from "../../../Infrastructure/repositories/api/PedidoMeep";
import { Product } from "../../../domains/catalog/aggregates/Product";
import { CatalogRepository } from "../../../Infrastructure/repositories/api/CatalogRepository";
import { useUi } from "../ui/UIContext";
import { Store } from "domains/catalog/aggregates/Store";
import { SeccionedProduct } from "domains/catalog/aggregates/SeccionedProduct";
import { ICardGroupDiscounts } from "domains/cashless/aggregates/ICardCashless";

interface ICatalogContext {
  catalog: Catalog | null;
  sync: () => void;
  getConfigurationProduct: (productType: ProdutoTipo) => Product | null;
  forceSync: () => Promise<void>;
  handleRestrictions: (restrictions: string[]) => void;
  handleDiscounts: (discounts: ICardGroupDiscounts[]) => void
}
const Context = createContext({} as ICatalogContext);
//container
const catalogRepository = CatalogRepository();

export const CatalogProvider: FC = ({ children }) => {
  const { isAuth } = useAuth();

  const [catalog, setCatalog] = useState<Catalog | null>(null);
  const [catalogRestritions, setCatalogRestritions] = useState<string[]>([]);
  const [groupDiscounts, setGroupDiscounts] = useState<ICardGroupDiscounts[]>([]);
  const { kiosk } = useKiosk();
  const { showLoading, hideLoading} = useUi();

  const filteredCatalog: Catalog | null = useMemo(() => {
    if (!catalog) {
      return null;
    }
    const updatedStores: Store[] = catalog?.stores.map(store => {
      const updatedSeccionedProducts: SeccionedProduct[] = store.seccionedProduct.map(section => {
        const filteredProducts = section.products
          .filter(product => !catalogRestritions.includes(product.id))
          .map(product => {
            const discount = groupDiscounts.find(discount => discount.produtoId === product.id);
            return discount ? { ...product, discount: discount.desconto } : product;
          });
        return { ...section, products: filteredProducts };
      })
      .filter(section => section.products.length > 0); // Remova SeccionedProducts sem produtos
      return { ...store, seccionedProduct: updatedSeccionedProducts };
    }) ?? [];

    return { ...catalog, stores: updatedStores };
  }, [catalog, catalogRestritions, groupDiscounts]);

  const handleRestrictions = useCallback(
    (restrictions: string[]) => {
      setCatalogRestritions(restrictions)
    },
    [],
  )

  const handleDiscounts = useCallback((discounts: ICardGroupDiscounts[]) => {
      setGroupDiscounts(discounts)
    },[]);

  const sync = useCallback(async () => {
    if (kiosk && isAuth) {
      try {
        const isChange = await catalogRepository.syncIfDiffAsync(kiosk.localId);
        if (isChange) {
          const _catalogSync = await catalogRepository.syncAsync()
          if (_catalogSync) {
            console.log("sync catalog on Context", _catalogSync);
            setCatalog(_catalogSync);
          }
        } else {
          console.log("sync catalog on Context NO CHANGE");
        }
      } catch (error) {
        console.error("sync catalog on error" + error);
      }
    }
  }, [kiosk, isAuth]);


  const forceSync = useCallback(async () => {
    showLoading()
    const _catalogSync = await catalogRepository.syncAsync()
    if (_catalogSync) {
      setCatalog(_catalogSync);
    }
    hideLoading();
    window.location.reload(); 
  }, [hideLoading, showLoading])

  const getConfigurationProduct = useCallback(
    (productType: ProdutoTipo) => {
      return catalog?.configProducts.find((configProduct) => configProduct.type === productType) ?? null;
    },
    [catalog]
  );

  useEffect(() => {
    catalogRepository.getAsync().then((catalog) => {
      if (catalog) {
        setCatalog(catalog);
      }
    });
    sync();
    return;
  }, [sync]);

  return <Context.Provider value={{ catalog: filteredCatalog, sync, getConfigurationProduct, forceSync, handleRestrictions, handleDiscounts }}>{children}</Context.Provider>;
};

export const CatalogConsumer = Context.Consumer;

export const useCatalog = () => {
  return useContext(Context);
};
