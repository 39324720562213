import React, { FC, useCallback } from "react";
import { Icon } from "@material-ui/core";
import "./SelectCardType.css";
import ".././PaymentModal.css"
import { TransactionType } from "../../../domains/order/agreggates/payment/transactionType";
import { Kiosk, SystemType } from "../../../domains/kiosk/aggregates/Kiosk";

interface SelectCardTypeProps {
  kiosk: Kiosk;
  open: boolean;
  total: number;
  onSelect: (value: TransactionType) => void;
  onClose: () => void;
  rechargePayment?: "Recharge" | "Payment" | "WalletRecharge"
}
export const SelectCardType: FC<SelectCardTypeProps> = ({
  total,
  onSelect,
  open,
  onClose,
  kiosk,
  rechargePayment
}) => {
  const isSystemType = useCallback(
    (systemType: SystemType) => {
      return kiosk?.configSymstem?.find((configSymstem) => configSymstem.systemType === systemType) ? true : false;
    },
    [kiosk]
  );

  return (
    <div className="selectCardType">
      <div className="text-selectCardType">Selecione a forma de pagamento.</div>
      <div className="buttonsContainer-selectCardType">
        {
          kiosk?.paymentCredit &&
          <div
            onClick={() => onSelect(TransactionType.credit)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">payment</Icon>
            <div> Crédito</div>
          </div>
        }
        {
          kiosk?.paymentDebit &&
          <div
            onClick={() => onSelect(TransactionType.debit)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">payment</Icon>
            <div> Débito</div>
          </div>
        }
        {
          kiosk?.paymentVoucher &&
          <div
            onClick={() => onSelect(TransactionType.voucher)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">payment</Icon>
            <div> Voucher</div>
          </div>
        }
        {
          (kiosk?.paymentPix && !(rechargePayment === "Payment")) &&
          <div
            onClick={() => onSelect(TransactionType.pix)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">pix</Icon>
            <div>Pix</div>
          </div>
        }
        {
          (kiosk?.paymentCashless /* && !isPreOrPos()*/) && !rechargePayment && (kiosk?.onlineCashlessControl || isSystemType(SystemType.pospayOrderPad)) &&
          <div
            onClick={() => onSelect(TransactionType.cashless)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">payment</Icon>
            <div> Cartão Cashless</div>
          </div>
        }
        {
          kiosk?.paymentWallet && !(rechargePayment === "WalletRecharge") &&
          <div
            onClick={() => onSelect(TransactionType.wallet)}
            className="button-selectCardType"
          >
            <Icon fontSize="large">wallet</Icon>
            <div> Cartão Wallet</div>
          </div>
        }
      </div>
      <div className="total-selectCardType">
        <div>Total:</div>
        <div>R$ {total.toFixed(2).replace(".", ",")}</div>
      </div>
    </div >
  );
};
