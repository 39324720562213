import React, { FC, useCallback, useMemo, memo } from "react";
import "./Keyboard.css";
import { Key } from "./key/Key";
import { Icon, IconButton } from "@material-ui/core";

let objectTecladoNumerico: string[][] = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  ["", "0", "back-space"],
];
let objectTecladoClear: string[][] = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  ["clear", "0", "back-space"],
];
let uperCasseTeclado: string[][] = [
  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "back-space"],
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ç"],
  ["Z", "X", "C", "V", "B", "N", "M", ",", "."],
  [" "],
];
let uperCasseTecladoCheck: string[][] = [
  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "back-space"],
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ç"],
  ["Z", "X", "C", "V", "B", "N", "M", ",", ".", "check"],
  [" "],
];
let emailobjectTeclado: string[][] = [
  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "_"],
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "back-space"],
  ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ç"],
  ["z", "x", "c", "v", "b", "n", "m", "@", ".", ".com", ".br"],
];

interface KeyboardProps {
  onKeyPress?: (keyPressed: string) => void;
  onPressEnter?: () => void;
  onPressBack?: () => void;
  onPressClear?: () => void;
  type?: "numeric" | "text" | "clear" | "email" | "upperCaseCheck";
  mapKey?: string[][];
  label?: string;
  onChange?: (inputValue: string) => void;
  value?: string;
  onClose?: () => void;
}
export const Keyboard: FC<KeyboardProps> = memo(
  ({ onKeyPress, onPressEnter, onPressBack, onPressClear, type, mapKey, onChange, value, label, onClose }) => {

    const keys = useMemo(() => (
        mapKey
        ? mapKey
        : type === "numeric"
        ? objectTecladoNumerico
        : type === "clear"
        ? objectTecladoClear
        : type === "email"
        ? emailobjectTeclado
        : type === "upperCaseCheck"
        ? uperCasseTecladoCheck
        : uperCasseTeclado
    ), [mapKey, type])

    const onPressKey = useCallback(
      (key: string) => {
        if (key === "check") {
          if (onPressEnter) {
            onPressEnter();
          }
        } else if (key === "back-space") {
          onPressBack && onPressBack();
          if (value) {
            onChange && onChange(value.substring(0, value.length - 1));
          }
        } else if (key === "clear") {
          onPressClear && onPressClear();
          onChange && onChange("");
        } else {
          onKeyPress && onKeyPress(key);
          onChange && onChange(value + key);
        }
      },
      [onKeyPress, onPressEnter, onPressBack, onChange, value, onPressClear]
    );

    const keyboardRender = useMemo(
      () => () =>
      keys.map((line, k) => (
          <div className={"keyboardLine"} key={k}>
            {line.map((key, _key) => (
              <div
                key={_key}
                style={{
                  flex: (key === "check" || key === "back-space") && type !== "numeric" && type !== "clear" ? 2 : 1,
                }}
                className={"containerKeyboardKey"}
              >
                <Key onClick={onPressKey}>{key}</Key>
              </div>
            ))}
          </div>
        )),
      [onPressKey, keys, type]
    );

    return (
      <div className="keyboardContainer">
        {onClose && (
          <div className="keyboardOnClose">
            <IconButton onClick={onClose}>
              <Icon fontSize={"large"}>close</Icon>
            </IconButton>
          </div>
        )}
        {label && <div className="keyboardLabel">{label}</div>}
        {value !== undefined && <div className="keyboardValue">{value}</div>}
        <div style={{ maxHeight: keys.length * 85 + 80 }} className="keyboardKeys">
          {keyboardRender()}
        </div>
      </div>
    );
  }
);
