import { useCatalog } from "application/contexts/catalog/CatalogContext";
import QRCode from "qrcode.react";
import React, { FC, Fragment, useCallback, useMemo } from "react";
import { hideCnpj, hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { IObservations, IOrderData, IOrderProduct } from "../IOrderData";
import "./Resume.css";

interface ResumeProps {
  order: IOrderData;
  kiosk: Kiosk;
}

interface ICategoryProducts {
  category: string;
  products: IOrderProduct[];
}

export const Resume: FC<ResumeProps> = ({ order, kiosk }) => {
  let previousStoreName: string = "";
  const { catalog } = useCatalog();

  const getOptonal = useCallback((produto: IOrderProduct) => {
    let _adicionais: IObservations[] = [];
    let _observacoes: IObservations[] = [];

    produto.observations.forEach((it) => {
      if (
        it.options
          .filter((f) => f.description.length > 0)
          .some((op) => !op.productId)
      ) {
        _observacoes.push(it);
      } else {
        if (it.options.length > 0 && it.description !== "Observação") {
          _adicionais.push(it);
        }
      }
    });

    return {
      adicionais: _adicionais,
      observacoes: _observacoes,
    };
  }, []);

  const categories = useMemo(() => {
    const record = order.products.reduce(
      (agrupado: Record<string, IOrderProduct[]>, produto) => {
        const { category } = produto;
        if (agrupado[category ?? "Padrão"]) {
          agrupado[category ?? "Padrão"].push(produto);
        } else {
          agrupado[category ?? "Padrão"] = [produto];
        }
        return agrupado;
      },
      {}
    );

    const _categories: ICategoryProducts[] = [];

    Object.keys(record).forEach((key) => {
      _categories.push({
        category: key,
        products: record[key],
      });
    });

    return _categories;
  }, [order.products]);

  const password = useMemo(() => order.orderPassword, [order.orderPassword]);

  const removerZerosEsquerda = (password: string) => {
    return password.replace(/^0+/, "");
  };

  return (
    <div id="resumoLayoutPrinter">
      <div className="container-ResumoLayoutPrinter">
        <div className="logo-ResumoLayoutPrinter">
          <img
            width={150}
            src={require("./../../../assets/images/logo-meep.png")}
            alt="logo"
          />
        </div>
        <div className="localName-ResumoLayoutPrinter">{kiosk.localName}</div>

        <div className="friendlyIdResume">
          <span className="textLabelPedido">Pedido:</span>{" "}
          <span className="textPedido">{order.orderCode}</span>
        </div>

        {kiosk.mustEnableManagementPassword && (
          <div className="productPassWordOrderResume">
            <span className="textLabelSenha">
              {password === null || password === undefined || password === ""
                ? "SEM SENHA"
                : "SENHA"}
            </span>{" "}
            <span className="textSenha">
              {" "}
              {password === null || password === undefined || password === ""
                ? "Dirija-se ao balcão"
                : removerZerosEsquerda(password)}
            </span>
          </div>
        )}

        {order.pedidoPOSId && (
          <div>
            <QRCode
              width={500}
              value={order.pedidoPOSId
                .replace("-", "")
                .replace("-", "")
                .replace("-", "")
                .replace("-", "")
                .replace("-", "")}
            />
          </div>
        )}
        <div className="ordersItems-ResumoLayoutPrinter">
          {categories.map((category, key) => (
            <div key={key}>
              {kiosk.printCategoryInProductName && (
                <div className="title-ResumoLayoutPrinter">
                  {category.category}
                </div>
              )}
              {category.products.map((product, index) => {
                const { adicionais, observacoes } = getOptonal(product);

                const storeNameToDisplay =
                  previousStoreName !== product.storeName &&
                  catalog &&
                  catalog?.stores?.length > 1
                    ? product.storeName
                    : null;
                previousStoreName = product.storeName ?? "";

                return (
                  <Fragment key={index}>
                    {storeNameToDisplay && (
                      <div className="name-storeName-resumoLayoutPrinter">
                        {storeNameToDisplay}:
                      </div>
                    )}
                    <div className="ordersItem-ResumoLayoutPrinter">
                      <div className="text-ordersItem-ResumoLayoutPrinter">
                        <div className="name-ordersItem-ResumoLayoutPrinter">
                          {product.name}
                        </div>
                        <div className="price-ordersItem-ResumoLayoutPrinter">
                          R$
                          {(product.value * (product.quantity ?? 1))
                            .toFixed(2)
                            .replace(".", ",")}
                        </div>
                      </div>
                      {(adicionais.length > 0 || observacoes.length > 0) && (
                        <div className="composition-ordersItem-ResumoLayoutPrinter">
                          {adicionais.length > 0 && (
                            <div className="options-ordersItem-ResumoLayoutPrinter">
                              {adicionais.map((obs) => (
                                <div className="optionConsumer-ordersItem-ResumoLayoutPrinter">
                                  <div className="descriptionOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                    {obs.description}:
                                  </div>
                                  <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                    {obs.options.map((option) => (
                                      <div className="optionItemOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                        <div>
                                          {option.quantity}x{" "}
                                          {option.description}
                                        </div>
                                        <div>
                                          R$
                                          {option.productValue
                                            .toFixed(2)
                                            .replace(".", ",")}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {observacoes.length > 0 && (
                            <div className="options-ordersItem-ResumoLayoutPrinter">
                              {observacoes.map((opcaoDoConsumidor) => (
                                <div className="optionConsumer-ordersItem-ResumoLayoutPrinter">
                                  <div className="descriptionOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                    {opcaoDoConsumidor.description}:
                                  </div>
                                  <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                    {opcaoDoConsumidor.options.map((option) => (
                                      <div className="optionItemOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                        <div>{option.description}</div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ))}

          {order?.orderObservation && (
            <div className="observation-ResumoLayoutPrintern">
              {order.orderObservation}
            </div>
          )}

          <div className="amount-ResumoLayoutPrinter">
            <div>Total:</div>{" "}
            <div>R${order.total.toFixed(2).replace(".", ",")}</div>
          </div>
          <div className="resume-layout-dotted-separator" />
          {order?.client.name && (
            <div className="kioskName">
              {order?.client.name === kiosk.kioskName
                ? ""
                : "Nome: " + order?.client.name}
            </div>
          )}
          {order?.client.document && order.client.document.length === 11 && (
            <div className="kioskName">
              CPF:{hideCpf(order?.client.document)}
            </div>
          )}
          {order?.client.document && order.client.document.length === 14 && (
            <div className="kioskName">
              CNPJ:{hideCnpj(order?.client.document)}
            </div>
          )}
          {order?.client.table && (
            <div className="kioskName">Mesa: {order?.client.table}</div>
          )}
          <div className="date-ResumoLayoutPrinter">
            {order.orderDate ? new Date(order.orderDate).toLocaleString("pt-BR") : ''}
          </div>
          <div className="date-ResumoLayoutPrinter">{kiosk.kioskName}</div>
        </div>
      </div>
    </div>
  );
};
