import { IOrderProduct } from "components/layoutsPrinter/IOrderData";
import { IPrintOrder } from "components/layoutsPrinter/IPrintOrder";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { OrderResume } from "domains/order/agreggates/order/OrderResume";
import { IPrintSector } from "Infrastructure/repositories/api/ConfigTotemMeep";
import moment from "moment";

export const parsePrintRemote = (
  printOrder: IPrintOrder,
  kiosk: Kiosk,
  printFile: string | null
) => {
  const orderResume: OrderResume = {
    id: printOrder.data.friendlyId,
    businessName: kiosk?.localName ?? "",
    customer: `${printOrder.data.client?.name ?? ""} '--' ${
      printOrder.data?.orderObservation ?? ""
    }`,
    tag: printOrder.data.client.tag ?? "",
    responsable: "Totem",
    pointOfSale: kiosk?.kioskName ?? "TOTEM",
    date: moment.utc().toDate(),
    isCanceled: false,
    canceledBy: null,
    totalValue: printOrder.data.total,
    details: printOrder.data.products.map((it: IOrderProduct) => ({
      quantity: it.quantity ?? 1,
      productName: it.name,
      productCategory: it.category ?? "",
      value: it.value,
      totalValue: it.value * (it?.quantity ?? 1),
      notes: it.observations?.map((obs) => ({
        description: obs.description,
        options: obs.options.map((op) => op.quantity + "x -" + op.description),
      })),
      orderNumber: it.id,
      printerName:
        findPrinterNameByProductId(
          kiosk?.printingSectors ?? [],
          it.id,
          kiosk?.id ?? ""
        ) ??
        it.printerName ??
        "",
      attendantName: null,
      operatorName: null,
    })),
    orderFriendlyId:
      printOrder.data.friendlyId +
      " -" +
      (printOrder.data.orderObservation
        ? "(" + printOrder.data.orderObservation + ")"
        : "") +
      (printOrder.data.client?.table
        ? "(mesa:" + printOrder.data.client.table + ")"
        : ""),
    prismNumber: printOrder.data.client?.table ?? "",
    table: printOrder.data.client?.table ?? "",
    note: printOrder.data?.orderObservation ?? "",
    imageBase64: printFile ?? "",
    duplicatePrint: kiosk?.duplicateRemotePrint,
  };

  return orderResume;
};

const findPrinterNameByProductId = (
  printingSectorsContainer: IPrintSector[],
  productId: string,
  totemId: string
) => {
  let printerName;
  printingSectorsContainer.forEach((it) => {
    if (it.productsIds.includes(productId) && it.totemsIds.includes(totemId)) {
      printerName = it.printerName;
    }
  });
  return printerName;
};
