import { INfcePrintOrderData } from "components/layoutsPrinter/INfcePrintOrderData";
import { IPrintOrder, PrintOrderType } from "components/layoutsPrinter/IPrintOrder";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { IEmitedNfce } from "modules/order/domain/models/INfce";
import { v4 } from "uuid";

export const CreatePrintNfceUseCase = (nf: IEmitedNfce[]): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  nf.forEach(it => {
    const nfceData: INfcePrintOrderData = {
      cnpj: it.companyDocument,
      company: it.razaosocial,
      danfe: it?.danfe ?? '',
      emissionDate: it.emissionDate,
      itens: it.itens,
      key: it?.key ?? '',
      mustPrintCompleteDANFE: it.mustPrintCompleteDANFE,
      numbering: it?.numbering ?? '',
      protocol: it?.protocol ?? '',
      serie: it?.serie ?? '',
      status: it?.status ?? '',
      totalValue: it.totalValue,
      client: it?.client ?? '',
      header: it.header,
      text: it.text,
      infoInteresseContribuinte: it.infoInteresseContribuinte,
      infoInteresseFisco: it.infoInteresseFisco,
    }

    const printOrder: IPrintOrder = {
      createdAt: new Date(it.emissionDate),
      data: nfceData,
      id: v4(),
      printType: PrintType.NONE,
      type: PrintOrderType.NFCE,
    }

    ordersToPrint.push(printOrder)
  })

  return ordersToPrint;
}