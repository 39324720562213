import { Button, IconButton, Tooltip } from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  InfoRounded,
  Remove,
} from "@material-ui/icons";
import { CommandEletronicExtract } from "Infrastructure/repositories/api/ExtractRepository";
import { moneyMask } from "corss-cutting/masks/money";
import React, { FC, useCallback, useEffect, useState } from "react";
import { hideCpf, toDateAndTime } from "utils/utils";
import styles from "./PospaidExtract.module.scss";

interface IPospaidExtract {
  extract: CommandEletronicExtract;
  onClickAdd: () => void;
  onClickPayment: () => void;
  type: "cashless" | "orderPad" | "school";
  serviceRate: number | null;
  serviceRatePercent?: number;
}

export const PospaidExtract: FC<IPospaidExtract> = ({
  extract,
  onClickAdd,
  onClickPayment,
  type,
  serviceRate,
  serviceRatePercent,
}) => {
  const [isFullHD, setIsFullHD] = useState(window.innerHeight >= 1920);
  const [open, setOpen] = useState(false);

  const handleTooltipToggle = useCallback(() => {
    setOpen((prev) => !prev);
  },[]);

  useEffect(() => {
    const handleResize = () => {
      setIsFullHD(window.innerHeight >= 1920);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id={styles.PospaidExtract}
      style={{ marginTop: isFullHD ? "0" : "-100px" }}
    >
      <div>
        <p style={{ fontSize: isFullHD ? 35 : 25 }}>{extract.user?.name}</p>
        <p style={{ fontSize: isFullHD ? 25 : 18 }}>
          {hideCpf(extract.user?.document ?? "")}
        </p>
      </div>

      <div className={styles.content}>
        <div>
          {extract.orders.map((it, key) => (
            <div key={key} className={styles.item}>
              <div>
                <span>
                  {toDateAndTime(String(it.createdAt))}
                </span>
                <span>
                  {it.items.reduce(
                    (acc, curr) =>
                      acc +
                      (!curr.isCanceled && !curr.isTransfered
                        ? curr.totalValue
                        : 0),
                    0
                  ) === 0 ? (
                    <Remove style={{ color: "#a2a2a2" }} />
                  ) : !it.isPayment ? (
                    <ArrowDropUp style={{ color: "#C00D1E" }} />
                  ) : (
                    <ArrowDropDown style={{ color: "#399C54" }} />
                  )}
                </span>
              </div>
              <>
                {it.items.map((item, _key) => (
                  <div
                    key={_key}
                    style={{
                      textDecoration: item.isCanceled
                        ? "line-through"
                        : undefined,
                      color: item.isCanceled
                        ? "#ED0029"
                        : item.isTransfered
                        ? "#a2a2a2"
                        : undefined,
                    }}
                  >
                    <span>{`${item.quantity}x ${item.name} ${
                      item.isTransfered ? "(Transferido)" : ""
                    }`}</span>
                    <span>{`${10 < 0 ? "- " : ""}${moneyMask(
                      item.totalValue.toFixed(2)
                    )}`}</span>
                  </div>
                ))}
                <div>
                  <span>
                    <b>Total</b>
                  </span>
                  <span>
                    <b>
                      {moneyMask(
                        it.items
                          .reduce(
                            (acc, curr) =>
                              acc +
                              (!curr.isCanceled && !curr.isTransfered
                                ? curr.totalValue
                                : 0),
                            0
                          )
                          .toFixed(2)
                      )}
                    </b>
                  </span>
                </div>
              </>
            </div>
          ))}
        </div>

        <div>
          <div className={styles.item}>
            <div />
            <div>
              <span>Subtotal:</span>
              <span>{moneyMask(extract.totalToPayWithoutFee.toFixed(2))}</span>
            </div>
            {serviceRate ? (
              <div>
                <span style={{ display: "flex", alignItems: "center" }}>
                  Taxa de serviço:
                  <Tooltip
                    title={`Taxa de serviço: ${
                      (serviceRatePercent ?? 0) * 100
                    }%`}
                    placement="top"
                    open={open}
                  >
                    <IconButton onClick={handleTooltipToggle}>
                      <InfoRounded
                        style={{ color: "#a2a2a2", margin: "4px 0 0 4px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
                <span>{moneyMask(serviceRate.toFixed(2))}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.footer}>
            <div>
              <span>
                <b>Total a pagar: </b>
              </span>
              <span style={{ color: "#399C54" }}>
                <b>{moneyMask(extract.totalToPayWithFee.toFixed(2))}</b>
              </span>
            </div>

            {type === "cashless" && (
              <Button
                variant="contained"
                fullWidth
                onClick={onClickAdd}
                style={{ backgroundColor: "#1af" }}
              >
                Adicionar crédito
              </Button>
            )}

            {type === "orderPad" && extract.totalToPayWithFee > 0 && (
              <Button
                variant="contained"
                fullWidth
                onClick={onClickPayment}
                style={{ backgroundColor: "#1a5" }}
              >
                Pagar conta
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
