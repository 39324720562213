import React, { FC, useCallback, useMemo } from "react";
import {
  OrderLog,
  ProdutoPedidoPosOrderLog,
} from "../../../../domains/order/agreggates/order/Order";
import { Grid, Container } from "@material-ui/core";
import moment from "moment";
import "./OrderLogItemPreview.css";
import { OrderItem } from "../../../../domains/order/agreggates/order/OrderItem";
import { OrderItemPreview } from "./orderItemPreview/OrderItemPreview";
import { useAdmin } from "../../../../application/contexts/admin/adminContext";
import { TransactionTypeLabel } from "../../../../domains/order/agreggates/payment/transactionType";
import {
  PedidoPos,
  ProdutoPedidoPos,
  ProdutoTipo,
} from "Infrastructure/repositories/api/PedidoMeep";
import { ProdutoPedidoPosPreview } from "./produtoPedidoPosPreview/ProdutoPedidoPosPreview";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { PedidoPosPreview } from "./pedidoPosPreview/PedidoPosPreview";
import { EletronicInvoiceTicket } from "domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { CreatePrintNfceUseCase } from "modules/order/application/useCases/CreatePrintNfceUseCase";
import {
  PrinterStore,
  usePrinterStore,
} from "application/contexts/printer/PrinterStore";
import { useShallow } from "zustand/react/shallow";
import { CreatePrintOrderUseCase } from "modules/order/application/useCases/CreatePrintOrderUseCase";

interface OrderLogItemPreviewProps {
  orderLog: OrderLog;
  onClose: () => void;
  isTicketPrinter: boolean;
  onClickPrintProdutoPosTicket: (produtoPos: ProdutoPedidoPos) => void;
  onClickPrintPedidoPos: (pedidoPos: PedidoPos) => void | undefined;
}
export const OrderLogItemPreview: FC<OrderLogItemPreviewProps> = ({
  orderLog,
  isTicketPrinter,
  onClickPrintProdutoPosTicket,
  onClickPrintPedidoPos,
}) => {
  const { verifyRoles, operators, selectedOperator } = useAdmin();
  const { kiosk } = useKiosk();

  const [addOrdersToPrintQueue] = usePrinterStore(
    useShallow((state: PrinterStore) => [state.addOrders])
  );

  const DEPRECATED_onClickPrintItem = useCallback(
    (orderItem: OrderItem) => {
      if (kiosk) {
        addOrdersToPrintQueue(
          CreatePrintOrderUseCase(
            {
              clienteEstabelecimento: "",
              clienteEstabelecimentoObject: {},
              codigoPedido: "",
              dadosTransacao: "",
              produtos: "",
              produtosObject: [
                {
                  impressora: orderItem.printerName,
                  nome: orderItem.name,
                  nomeProduto: orderItem.name,
                  opcoesDoConsumidor: "",
                  opcoesDoConsumidorObject: [],
                  produtoId: orderItem.productId,
                  ticketId: "",
                  valor: orderItem.productPrice,
                },
              ],
              tagCashless: "",
              tipoAdquirente: 0,
              valorTotal: orderItem.totalPrice,
            },
            kiosk
          )
        );
      }
    },
    [addOrdersToPrintQueue, kiosk]
  );

  const onClickPrint = useCallback(
    (nf: EletronicInvoiceTicket[]) => {
      addOrdersToPrintQueue(CreatePrintNfceUseCase(nf));
    },
    [addOrdersToPrintQueue]
  );

  const nfs = useMemo(() => {
    return orderLog.nfce?.filter((it) => it.danfe);
  }, [orderLog.nfce]);

  return (
    <Container className={"container-orderLogItemPreview"}>
      <Grid sm={12} item className="content-orderLogItemPreview">
        <Grid sm={12} item className="horario-orderLogItemPreview">
          Pedido: {orderLog.friendlyId}
        </Grid>
        <Grid sm={12} item className="horario-orderLogItemPreview">
          Status:{" "}
          {orderLog.paymentApproved
            ? "Pagamento aprovado"
            : "Pagamento pendente"}
        </Grid>
        <Grid sm={12} item className="horario-orderLogItemPreview">
          Id: {orderLog.id}
        </Grid>
        <Grid sm={12} item className="horario-orderLogItemPreview">
          Horario:{" "}
          {moment(orderLog.createdAt).format("DD/MM/YYYY [as] HH:mm:ss")}
        </Grid>
        <Grid sm={12} item className="quantidade-orderLogItemPreview">
          Quantidade: {orderLog.orderItems.length}
        </Grid>
        <Grid sm={12} item className="total-orderLogItemPreview">
          Total: R${orderLog.totalValue.toFixed(2).replace(".", ",")}
        </Grid>
        {orderLog?.payment && (
          <Grid sm={12} item className="total-orderLogItemPreview">
            Forma de pagamento:{" "}
            {TransactionTypeLabel.get(orderLog.payment?.paymentMethod)}
          </Grid>
        )}
        {orderLog?.payment?.cardNumber && (
          <Grid sm={12} item className="total-orderLogItemPreview">
            Cartão: {orderLog.payment.cardNumber}
          </Grid>
        )}
        <Grid sm={12} item className="total-orderLogItemPreview">
          Produtos:
        </Grid>
        <Grid sm={12} item className="orders-orderLogItemPreview">
          {!(kiosk?.printType === PrintType.RESUME) ? (
            orderLog.pedidoPOS ? (
              <>
                <ProdutoPedidoPosPreview indice />
                {orderLog.pedidoPOS.produtosObject
                  .filter((it) => it.tipoProduto !== ProdutoTipo.TaxaServico)
                  .map((produto: ProdutoPedidoPosOrderLog, key) => {
                    return (
                      <ProdutoPedidoPosPreview
                        key={key}
                        isPaid={orderLog.paymentApproved}
                        isTicketPrinter={isTicketPrinter}
                        index={key + 1}
                        onClickPrint={() =>
                          onClickPrintProdutoPosTicket(produto)
                        }
                        permissionPrint={verifyRoles("ReimpressaoTicketTotem")}
                        produtoPedidoPosPreview={produto}
                        operators={operators}
                        currentOperator={selectedOperator}
                      />
                    );
                  })}
              </>
            ) : (
              <>
                <OrderItemPreview indice />
                {orderLog.orderItems.map((orderItem: OrderItem, key) => {
                  return (
                    <OrderItemPreview
                      isPayd={orderLog.paymentApproved}
                      key={key}
                      isTicketPrinter={isTicketPrinter}
                      onClickPrint={() =>
                        DEPRECATED_onClickPrintItem(orderItem)
                      }
                      permissionPrint={verifyRoles("ReimpressaoTicketTotem")}
                      orderItem={orderItem}
                    />
                  );
                })}
              </>
            )
          ) : (
            <>
              <PedidoPosPreview indice />

              <PedidoPosPreview
                isPaid={orderLog.paymentApproved}
                isTicketPrinter={isTicketPrinter}
                permissionPrint={verifyRoles("ReimpressaoTicketTotem")}
                pedidoPosPreview={orderLog.pedidoPOS}
                orderLogItems={orderLog.orderItems}
                operators={operators}
                currentOperator={selectedOperator}
                onClickPrintPedidoPos={() =>
                  orderLog.pedidoPOS &&
                  onClickPrintPedidoPos(orderLog.pedidoPOS)
                }
              />
            </>
          )}
        </Grid>
        <Grid sm={12} item className="total-orderLogItemPreview">
          Logs:
        </Grid>
        <Grid sm={12} item className="orders-orderLogItemPreviewList">
          {orderLog?.log?.map((it, key) => (
            <div key={key}>{`${moment(it.time).format(
              "DD/MM/YYYY [as] HH:mm:ss"
            )} - ${it.message}`}</div>
          ))}
        </Grid>
        {verifyRoles("ReimpressaoTicketTotem") && nfs?.length && (
          <Grid
            sm={12}
            item
            className="total-orderLogItemPreview-nf"
            onClick={() => onClickPrint(orderLog.nfce!)}
          >
            Reimprimir Nota Fiscal
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
