import { ICardGroupDiscounts } from "domains/cashless/aggregates/ICardCashless";
import { Kiosk, SystemType } from "domains/kiosk/aggregates/Kiosk";
import { IOrder, ProdutoTipo } from "modules/order/domain/models/IOrder";
import { roundValue } from "utils/utils";

export const UpdateGroupDiscount = (
  order: IOrder,
  kiosk: Kiosk | null,
  groupDiscounts: ICardGroupDiscounts[]
) => {
  if (order.orderItems) {
    let newOrderItems = order.orderItems.map((item) => {
      const discount = groupDiscounts.find(
        (discount) => discount.produtoId === item.productId
      );
      if (discount) {
        const discountAmount = item.price * (discount.desconto / 100);
        return {
          ...item,
          price: item.price - discountAmount,
          totalPrice: item.price - discountAmount,
          productPrice: item.price - discountAmount,
        };
      } else {
        return item;
      }
    });

    let serviceFee = 0;
    if (
      kiosk &&
      kiosk.serviceRate &&
      kiosk.configSymstem.find(
        (it) => it.systemType === SystemType.prepayTicket
      )
    ) {
      const totalPriceProduct = newOrderItems
        .map((item) => {
          return item.isWeight
            ? item.totalPrice
            : item.productType !== ProdutoTipo.TaxaServico && !item.noServiceFee
            ? item.totalPrice * item.quantity
            : 0;
        })
        .reduce((prev, current) => prev + current, 0);

      serviceFee =
        roundValue(kiosk.serviceRatePercent * totalPriceProduct);
    }

    const newOrderItemsWithServiceFee = newOrderItems.map((it) => {
      return it.productType === ProdutoTipo.TaxaServico
        ? {
            ...it,
            price: serviceFee,
            realPrice: serviceFee,
            totalPrice: serviceFee,
            productPrice: serviceFee,
          }
        : it;
    });

    const totalPrice = newOrderItemsWithServiceFee
      .map((item) => {
        if (item.productType === ProdutoTipo.TaxaServico) {
          return serviceFee;
        } else {
          return item.isWeight
            ? item.quantity * item.realPrice
            : item.quantity * item.totalPrice;
        }
      })
      .reduce((acumulado, atual) => atual + acumulado, 0);

    const newOrder: IOrder = {
      ...order,
      totalWithoutDiscount: order.totalValue,
      totalValue: totalPrice,
      orderItems: newOrderItemsWithServiceFee,
      groupDiscountOrder: true,
    };
    return newOrder;
  } else {
    return {
      ...order,
      groupDiscountOrder: true,
    };
  }
};
