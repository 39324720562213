import { TransactionType } from "domains/order/agreggates/payment/transactionType";
import React, { FC, memo, useCallback } from "react";
import { hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { IWalletRechargeOrderData } from "../IWalletRechargeOrderData";
import "./WalletRechargeLayout.css";

interface WalletRechargeProps {
  order: IWalletRechargeOrderData;
  kiosk: Kiosk;
}

export const WalletRecharge: FC<WalletRechargeProps> = memo(
  ({ kiosk, order }) => {
    const getTransactionType = useCallback((type: TransactionType) => {
      switch (type) {
        case TransactionType.credit:
          return "Crédito";
        case TransactionType.debit:
          return "Débito";
        case TransactionType.pix:
          return "Pix";
        default:
          return "";
      }
    }, []);

    return (
      <div id="WalletRechargeLayout">
        <div className="header">
          <h5>{kiosk.localName}</h5>
          <div>
            <span>Pedido:</span>
            <span>{order.order.friendlyId}</span>
          </div>
          <div className="divisor" />
          <div>
            <b>Recarga Wallet</b>
            <span>
              <b>{`R$ ${order.order.totalValue
                .toFixed(2)
                .replace(".", ",")}`}</b>
            </span>
          </div>
        </div>
        <div className="divisor" />
        <div className="content">
          {order.order.customer?.document && (
            <p>{`CPF: ${hideCpf(order.order.customer.document)}`}</p>
          )}
          <p>{`Tag: ${(order.order.customer?.tag ?? "").replace(
            /([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g,
            `$1-`
          )}`}</p>
          <p>{`Saldo: R$${order.balance.toFixed(2).replace(".", ",")}`}</p>
          <div className="divisor" />
          <p>{`Terminal: ${kiosk.kioskName}`}</p>
          <p>{`Data: ${new Date(order.order.createdAt).toLocaleString(
            "pt-BR"
          )}`}</p>
          {order.order.payment?.paymentMethod && (
            <p>{`Pagamento: ${getTransactionType(
              order.order.payment.paymentMethod
            )}`}</p>
          )}
          <div className="divisor" />
          <p style={{ fontSize: 12 }}>{`Ido: ${order.order.id}`}</p>
          <p style={{ fontSize: 12 }}>{`Idw: ${order.walletId}`}</p>
        </div>
      </div>
    );
  }
);
