import { BarCodeRepository } from 'Infrastructure/repositories/api/BarCodeRepository';
import { useCatalog } from 'application/contexts/catalog/CatalogContext';
import { useKiosk } from 'application/contexts/kiosk/KioskContext';
import { useSession } from 'application/contexts/session/SessionContext';
import { useUi } from 'application/contexts/ui/UIContext';
import { Product } from 'domains/catalog/aggregates/Product';
import { ICartContext } from 'modules/cart/domain/interfaces/IUseCaseCart';
import { useCartStore } from 'modules/cart/infra/store/CartStore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const barCodeRepository = BarCodeRepository();

export const UseBarCode = (weighing?: boolean) => {
    const [codeValue, setcodeValue] = useState("");
    const refDiv = useRef<HTMLDivElement | null>(null);
    const [alert, setalert] = useState(false);
    const [addProductCart, setConfigCart] = useCartStore((state: ICartContext) => [
        state.addProductCart,
        state.setConfigCart
    ]);

    const { verifySessionIsOpen } = useSession();

    const { push } = useHistory();
    const { transitionPageAnimation } = useUi();
    const { catalog } = useCatalog();
    const { kiosk } = useKiosk();

    useEffect(() => {
        if(kiosk?.enableBarCode) {
            setConfigCart({
                fullscreen: kiosk?.barCodeMode,
                disableCloseButton: kiosk?.barCodeMode,
                openOnCreate: weighing ? false : true,
                label: 'Finalizar'
            });
        }
    }, [kiosk, setConfigCart, weighing]);

    const onSubmit = useCallback(async () => {
        const hasOpenSession = await verifySessionIsOpen();

        if (codeValue && hasOpenSession) {
            let product: Product | null = null;

            // Tenta obter o produto diretamente
            product = await barCodeRepository.getProduct(codeValue);

            console.log({codeValue, product})

            if (!product) {
                // Se não encontrar, tenta quebrar o código
                if (codeValue.charAt(0) === '2') {
                    const stringWithoutFirstAndLast = codeValue.slice(1, -1);
                    const sixFirstDigits = stringWithoutFirstAndLast.slice(0, 6);
                    const fiveLastDigits = stringWithoutFirstAndLast.slice(-5);

                    product = await barCodeRepository.getProduct(sixFirstDigits);

                    if (product) {
                        const quantidadeFrancionada = (Number(fiveLastDigits) / 1000)
                        const quantidadeFracionadaFormatada = Number(quantidadeFrancionada.toFixed(5))
                        addProductCart({...product, isWeight: true, hideCatalog: true, price: (Number(fiveLastDigits) / 1000) * product.price}, quantidadeFracionadaFormatada, [], product.adultHood);
                    }
                }
            } else {
                // Produto encontrado diretamente
                addProductCart({...product, isBarcodeUnity: true}, 1, [], product.adultHood);
            }

            if (!product) {
                setalert(true);
                setTimeout(() => {
                    setalert(false);
                }, 4000);
            }
        }

        setcodeValue("");
    }, [verifySessionIsOpen, codeValue, addProductCart]);

    const onkeypress = useCallback(
        (key: string) => {
            if (key === "Enter") {
                console.log(codeValue);

                onSubmit();
            } else {
                setcodeValue((prev) => prev + key);
            }
        },
        [codeValue, onSubmit]
    );

    useEffect(() => {
        const eventListener = (value: KeyboardEvent) => {
            // if (!openPaymentModal) {
                onkeypress(value.key);
            // }
        };

        window.addEventListener("keypress", eventListener);
        return () => {
            window.removeEventListener("keypress", eventListener);
        };
    }, [onkeypress]);

    const focus = () => {
        refDiv.current?.focus();
    };

    const onClickComprarManualmente = useCallback(async () => {
        const hasOpenSession = await verifySessionIsOpen();
        if (hasOpenSession) {
            transitionPageAnimation(() => {
                if (catalog?.stores) {
                    if (catalog.stores.length === 1) {
                        push("/catalog/store/" + 0);
                    } else {
                        push("/catalog/");
                    }
                }
            });
        }
    }, [push, catalog, transitionPageAnimation, verifySessionIsOpen]);

    return {
        refDiv,
        onClickComprarManualmente,
        kiosk,
        focus,
        alert,
        onkeypress,

    }
}
