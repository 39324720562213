import QRCode from "qrcode.react";
import React, { FC, memo, useCallback, useMemo } from "react";
import { hideCnpj, hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import {
  IObservations,
  IOptions,
  IOrderProduct,
  IProductType,
} from "../IOrderData";
import { ITicketPrintOrderData } from "../ITicketPrintOrderData";
import "./Ticket.css";

interface TicketProps {
  order: ITicketPrintOrderData;
  kiosk: Kiosk;
}

export const Ticket: FC<TicketProps> = memo(({ kiosk, order }) => {
  const calcOptionsValue = useCallback((product: IOrderProduct) => {
    return product.observations
      ?.flatMap((item) => item.options)
      .map((item) => {
        let valorTotalItemCobrado = item.quantity * item.productValue;
        let valorTotalItemGratis = item.quantityFree * item.productValue;

        if (valorTotalItemGratis <= 0) {
          valorTotalItemGratis = 0;
        }

        let diffTotal = valorTotalItemCobrado - valorTotalItemGratis;

        return Math.max(diffTotal, 0);
      })
      .reduce((prev, current) => prev + current, 0);
  }, []);

  const { adicionais, observacoes } = useMemo(() => {
    let _adicionais: IObservations[] = [];
    let _observacoes: IObservations[] = [];

    order.product.observations.forEach((o) => {
      if (
        o.options
          .filter((f) => f.description.length > 0)
          .some((s) => !s.productId)
      ) {
        _observacoes.push(o);
      } else {
        if (o.options && o.options.length) {
          let nOpcoes: IOptions[] = [];
          for (const ito of o.options) {
            if (!ito.isProductGeneralObservation && !!ito?.productId) {
              nOpcoes.push(ito);
            }
          }
          o.options = nOpcoes;
        }
        _adicionais.push(o);
      }
    });

    return {
      adicionais: _adicionais,
      observacoes: _observacoes,
    };
  }, [order.product.observations]);

  const totalTickets = useMemo(
    () =>
      order?.products.filter((it) => it.type !== IProductType.TaxaServico)
        .length,
    [order]
  );

  const password = useMemo(() => order?.orderPassword, [order]);

  const removerZerosEsquerda = (password: string | undefined) => {
    return password?.replace(/^0+/, "");
  };

  const getTotalFromItem = useCallback((ito: IOptions) => {
    let valorTotalItemCobrado = ito.quantity * ito.productValue;
    let valorTotalItemGratis = ito.currentFree * ito.productValue;

    if (valorTotalItemGratis <= 0) {
      valorTotalItemGratis = 0;
    }

    let diffTotal = Math.max(valorTotalItemCobrado - valorTotalItemGratis, 0);
    return diffTotal;
  }, []);

  const localName = useMemo(() => {
    const name = order.product?.storeName
      ? order.product.storeName === kiosk.localName
        ? kiosk.localName + " | " + order.product.storeName
        : kiosk.localName
      : kiosk.localName;
    return order.isReprint ? name + "- FR" : name;
  }, [kiosk.localName, order.isReprint, order.product]);

  return (
    <div id="ticketprodutoPedidoPos">
      <div className="container-ticketPrinter">
        <div className="logo">
          <img
            width={150}
            src={require("./../../../assets/images/logo-meep.png")}
            alt="logo"
          />
        </div>
        <div className="localName">{localName}</div>

        <div className="friendlyId">
          <span className="textLabelPedido">Pedido:</span>{" "}
          <span className="textPedido">{order.orderCode}</span>
        </div>

        {kiosk.mustEnableManagementPassword && (
          <div className="productPassWordOrder">
            <span className="textLabelSenha">
              {password === null || password === undefined || password === ""
                ? "SEM SENHA"
                : "SENHA"}
            </span>{" "}
            <span className="textSenha">
              {" "}
              {password === null || password === undefined || password === ""
                ? "Dirija-se ao balcão"
                : removerZerosEsquerda(password)}
            </span>
          </div>
        )}

        <div>
          <QRCode
            width={500}
            value={order.product.ticketId
              .replace("-", "")
              .replace("-", "")
              .replace("-", "")
              .replace("-", "")
              .replace("-", "")}
          />
        </div>

        <div className="ordersItems-ResumoLayoutPrinter">
          {kiosk.printCategoryInProductName && order.product?.category && (
            <div className="productCategory">{order.product.category}</div>
          )}
          <div className="text-ordersItem-ResumoLayoutPrinter">
            <div className="name-ordersItem-ResumoLayoutPrinter">
              {order.product.name}
            </div>
            <div className="price-ordersItem-ResumoLayoutPrinter">
              R$
              {(
                (order.product.value + calcOptionsValue(order.product)) *
                (order.product.quantity ?? 1)
              )
                .toFixed(2)
                .replace(".", ",")}
            </div>
          </div>

          {(adicionais.length > 0 || observacoes.length > 0) && (
            <div className="consumerOptions">
              {adicionais.length > 0 && (
                <>
                  {adicionais.map((add) => (
                    <>
                      {add.options.length > 0 && (
                        <div>
                          <div className="descricao">{add.description}:</div>
                          <div>
                            {add.options.map((opcao) => {
                              const valorFormatado = getTotalFromItem(opcao)
                                .toFixed(2)
                                .replace(".", ",");
                              return (
                                <div className="option">
                                  <div>
                                    {opcao.quantity}x{opcao.description}
                                  </div>
                                  <div>R${valorFormatado}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
              {observacoes.length > 0 && (
                <>
                  {observacoes.map((op) => (
                    <div>
                      <div className="descricao">{op.description}:</div>
                      <div>
                        {op.options.map((option) => (
                          <div className="option">
                            <div>{option.description}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}

          <div>{order.orderObservation}</div>
        </div>

        <div className="kioskName">
          {(order.index ?? 0) + 1}/{totalTickets}
        </div>

        <div className="resume-layout-dotted-separator" />
        {order?.client.name && (
          <div className="kioskName">
            {order?.client.name === kiosk.kioskName
              ? ""
              : "Nome: " + order?.client.name}
          </div>
        )}

        {!!order?.client.document && order.client.document.length === 11 && (
          <div className="kioskName">CPF:{hideCpf(order?.client.document)}</div>
        )}
        {!!order?.client.document && order.client.document.length === 14 && (
          <div className="kioskName">
            CPF:{hideCnpj(order?.client.document)}
          </div>
        )}
        {order?.client.table && (
          <div className="kioskName">Mesa: {order?.client.table}</div>
        )}

        <div className="date-ResumoLayoutPrinter">
          {order.orderDate?.toLocaleString("pt-BR")}
        </div>
        <div className="date-ResumoLayoutPrinter">{kiosk.kioskName}</div>
      </div>
    </div>
  );
});
