import React, { useState, useEffect, FC } from 'react'
import { TecladoVirtual } from '../../../components/tecladoVirtual/TecladoVirtual'
import { Button } from '@material-ui/core'
import "./InputField.css"

interface InputFieldProps {
    onPressContinuar: (inputValue?: string) => void;
    keyboardType: "numeric" | "alphanumeric" | "email";
    description: string;
    required?: boolean;
    type?: "CPF" | "PHONE";
    alert?: boolean;

    minInputLength?: number
    maxInputLength?: number

    textButtomAlert?: {
        confirm: string,
        cancel: string
    }
    buttonField?: {
        confirm: string,
        cancel: string
    }


    onPressCancel?: () => void;
    onPressNo?: () => void;


}

export const InputField: FC<InputFieldProps> = ({ onPressContinuar, keyboardType, minInputLength, maxInputLength, required, type, description, alert, onPressCancel, onPressNo }) => {

    const [inputValue, setinputValue] = useState("")
    const [disableContinuar, setdisableContinuar] = useState(false)
    const [enableTeclado, setenableTeclado] = useState(!alert)



    useEffect(() => {
        if (minInputLength) {
            if (inputValue.length >= minInputLength) {
                setdisableContinuar(false)
            } else {
                setdisableContinuar(true)
            }
        }
    }, [inputValue, minInputLength])

    const onpressKeyboard = (key: string) => {
        if (!maxInputLength || inputValue.length < maxInputLength) {
            setinputValue(prev => prev + key)
        }
    }
    const onpressback = () => {
        setinputValue(prev => prev.substring(0, prev.length - 1))
    }

    const onPressClear = () => {
        setinputValue("")
    }



    const onClickNaoInformar = () => {
        setinputValue("");
        if (onPressCancel) {
            onPressCancel();
        }
    }
    const onClickContinuar = () => {
        onPressContinuar(inputValue)
        setinputValue("");
    }
    const onClickInformar = () => {
        setenableTeclado(true)
    }



    const phoneMask = (Field: string) => {

        let newValue =
            (Field.length > 0 ? "(" : "") +
            Field.substring(0, 2) +
            (Field.length > 2 ? ")" : "") +
            Field.substring(2, 11)
        return newValue
    }


    return (
        <div className="inputField">
            {!enableTeclado ?
                <>
                    <div className="description-inputField">{description}</div>
                    <div className="buttons-inputField">
                        <Button
                            onClick={onClickNaoInformar}
                            className="button-inputField nao-informar-inputField"
                        >
                            NÃO
                        </Button>

                        <Button onClick={onClickInformar} className={"button-inputField continuar-inputField"}>
                            SIM
                        </Button>
                    </div>
                </>

                :

                <>
                    <div
                        className="description-inputField">
                        {description}
                    </div>

                    {type === "PHONE" ?
                        <div className="value-inputField">{phoneMask(inputValue)}</div> :
                        <div className="value-inputField">{inputValue}</div>
                    }
                    <div className="keyboar-container-inputField" style={{ width: keyboardType === "numeric" ? "100": "100%" }}>

                        <TecladoVirtual
                            type={keyboardType === "numeric" ? "clear" : keyboardType === "email" ? "email" : "text"}
                            onPressBack={onpressback}
                            onKeyPress={onpressKeyboard}
                            // onPressEnter={onpressEnter}
                            onPressClear={onPressClear}
                        />
                    </div>

                    <div className="buttons-inputField">
                        {!required && <Button
                            onClick={onClickNaoInformar}
                            className="button-inputField nao-informar-inputField"
                        >
                            {!required ? "NÃO INFORMAR" : "VOLTAR"}
                        </Button>
                        }
                        <Button
                            onClick={onClickContinuar}
                            disabled={disableContinuar}
                            className={disableContinuar ? "button-inputField continuar-inputField disabled-continuar-inputField" : "button-inputField continuar-inputField enable-continuar-inputField"}
                        >
                            CONTINUAR
                        </Button>
                    </div></>
            }

        </div>

    )
}
