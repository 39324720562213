import React, { FC, useState, useEffect, useCallback } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TransitionProps } from '@material-ui/core/transitions';
import { Slide, Icon } from '@material-ui/core';
import { TecladoVirtual } from '../../../../components/tecladoVirtual/TecladoVirtual';
import "./AlertaMaioridade.css"
import { useKiosk } from '../../../../application/contexts/kiosk/KioskContext';
import moment from 'moment';
import { UserDataRepositoryMeep } from '../../../../Infrastructure/repositories/api/UserDataRepository';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export interface AlertaMaioridadeProps {
    open: boolean,
    onClose: () => void
    onPressNao: () => void
    onAllow: () => void
}

// TODO: REfazer alerta maioridade
export const AlertaMaioridade: FC<AlertaMaioridadeProps> = ({ open, onClose, onPressNao, onAllow }) => {

    const [exibirTecladoCpf, setcpfTeclado] = useState(false)



    const _onAllow = () => {
        onAllow();
        onPressClose();
    }



    const onPressSim = () => {
        // userDataRepository.getUserData()
        setcpfTeclado(true)
    }


    const onPressClose = useCallback(() => {
        console.log("pressClose")
        setcpfTeclado(false)
        onClose();
    }, [onClose])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onPressClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            {
                !exibirTecladoCpf ?
                    <>
                        <DialogTitle style={{ fontSize: 30 }} id="alert-dialog-slide-title">{"VOCÊ POSSUI MAIS DE 18 ANOS?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                De acordo com a a lei 13.106/15, é proibido vender, fornecer, servir, ministrar ou entregar a menores bebida alcoólica ou outros produtos que possam causar dependência.
                            </DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            <Button 
                                onClick={onPressClose} 
                                color="primary" 
                                style={{ fontSize: 24, color: "#FFF", backgroundColor: "#f66", paddingLeft: 16, paddingRight: 16 }}
                                data-custom-id="majority-no-button"
                                data-custom-description="Não"
                            >
                                Não
                            </Button>
                            {
                                exibirTecladoCpf ?
                                    <Button 
                                        variant="contained" 
                                        onClick={onPressSim} 
                                        style={{ fontSize: 24, color: "#FFF", backgroundColor: "#1bb" }}
                                        data-custom-id="majority-continue-button"
                                        data-custom-description="Continuar"
                                    >
                                        Continuar
                                    </Button> :
                                    <Button 
                                        variant="contained" 
                                        onClick={onPressSim} 
                                        style={{ fontSize: 24, color: "#FFF", backgroundColor: "#1bb" }}
                                        data-custom-id="majority-yes-button"
                                        data-custom-description="Sim"
                                    >
                                        Sim
                                    </Button>
                            }
                        </DialogActions>
                    </>
                    :

                    < TecladoCpf onPressCancelar={onPressClose} onPressConfirmar={_onAllow}></TecladoCpf>
            }


        </Dialog >
    )
}

interface TecladoCpfProps {
    onPressConfirmar: (cpf: string) => void,
    onPressCancelar: () => void
}

//container
const userDataRepository = UserDataRepositoryMeep()

export const TecladoCpf: FC<TecladoCpfProps> = ({ onPressConfirmar, onPressCancelar }) => {


    const { kiosk } = useKiosk()


    const [cpfValue, setCpfValue] = useState("")
    const [disableContinuar, setdisableContinuar] = useState(true)

    const [alertNotAllow, setalertNotAllow] = useState(false)
    const [erroCPF, seterroCPF] = useState(false)

    const validarCpf = useCallback(() => {

        userDataRepository.getAsyncUserData(cpfValue, kiosk?.localId ?? "").then((user) => {
            console.log(user);
            const idade = moment().diff(user.birth, 'year');
            console.log("idade:", idade)
            if (idade >= 18) {
                setdisableContinuar(false);
            } else {
                setalertNotAllow(true);
                setCpfValue("")
            }
            seterroCPF(false);
        }, () => {
            seterroCPF(true);
        })
    }, [cpfValue, kiosk])

    useEffect(() => {
        if (cpfValue.length === 11) {
            validarCpf();
        } else {
            setdisableContinuar(true)
            seterroCPF(false);
        }
    }, [cpfValue, validarCpf])

    useEffect(() => {
        let timer: NodeJS.Timer | null = null;
        if (alertNotAllow) {
            timer = setTimeout(() => { setalertNotAllow(false) }, 5000)
        } else {
            if (timer) {
                clearTimeout(timer)
            }
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [alertNotAllow])

    const onpressKeyboard = (key: string) => {
        if (cpfValue.length < 11) {
            setCpfValue(prev => prev + key)
        }
    }
    const onpressback = () => {
        setCpfValue(prev => prev.substring(0, prev.length - 1))
    }

    const onPressClear = () => {
        setCpfValue("")
    }

    const cpfMaks = (cpf: string) => {
        let newValue = cpf.substring(0, 3) + (cpf.length > 3 ? "." + cpf.substring(3, 3) : "") + (cpf.length > 6 ? "." + cpf.substring(6, 3) : "") + (cpf.length > 8 ? "-" + cpf.substring(9, 2) : "")
        // 111.333.444-80
        return newValue
    }


    return (
        <div className={"container-TecladoCpf"}>
            {/* <DialogTitle id="alert-dialog-slide-title">{"VOCÊ POSSUI MAIS DE 18 ANOS?"}</DialogTitle> */}
            {
                <Dialog open={alertNotAllow}  >
                    <DialogTitle id="alert-dialog-slide-title">{"VOCÊ NÃO POSSUI PERMISSÃO PARA COMPRAR ESTE ITEM"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            De acordo com a a lei 13.106/15, é proibido vender, fornecer, servir, ministrar ou entregar a menores bebida alcoólica ou outros produtos que possam causar dependência.
                </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setalertNotAllow(false) }} color="primary" style={{ fontSize: 24, color: "#FFF", backgroundColor: "#f66", paddingLeft: 16, paddingRight: 16 }}>
                            OK
                            </Button>
                    </DialogActions>
                </Dialog>
            }
            <DialogContent>
                <div className="instrucao-TecladoCpf">Insira seu CPF</div>
                <div className="input-TecladoCpf">
                    <div className="value-TecladoCpf">
                        {cpfMaks(cpfValue)}
                    </div>
                    <div className="back-TecladoCpf" onClick={onpressback}>
                        <Icon className="icon-TecladoCpf" fontSize="large">backspace</Icon>
                    </div>
                </div>
                <div className="erro-TecladoCpf" onClick={onpressback}>
                    {
                        erroCPF &&
                        "Falha ao obter dados do cliente"
                    }
                </div>
                <TecladoVirtual
                    type="numeric"
                    onPressBack={onpressback}
                    onKeyPress={onpressKeyboard}
                    // onPressEnter={onpressEnter}
                    onPressClear={onPressClear}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onPressCancelar} color="primary" style={{ fontSize: 24, color: "#FFF", backgroundColor: "#f66", paddingLeft: 16, paddingRight: 16 }}>
                    Cancelar
                </Button>
                <Button
                    disabled={disableContinuar}
                    variant="contained"
                    onClick={() => { onPressConfirmar(cpfValue) }}
                    style={
                        {
                            fontSize: 24, color: "#FFF",
                            backgroundColor: "#1bb",
                            opacity: disableContinuar ? 0.2 : 1
                        }
                    }>
                    Continuar
                </Button>
            </DialogActions>
        </div >

    )
}
