import { useCatalog } from "application/contexts/catalog/CatalogContext";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import React, { useCallback, useMemo } from "react";
import { toInputDateString } from "utils/utils";
import "./Remote.css";
import { IObservations, IOptions, IOrderData, IOrderProduct } from "../IOrderData";

interface IRemoteProps {
  kiosk: Kiosk;
  order: IOrderData;
}

interface ICategoryProducts {
  category: string;
  products: IOrderProduct[];
}

export const Remote: React.FC<IRemoteProps> = ({ kiosk, order }) => {
  let previousStoreName: string = "";

  const { catalog } = useCatalog();

  const password = useMemo(
    () => order.orderPassword,
    [order.orderPassword]
  );

  const removerZerosEsquerda = (password: string | undefined) => {
    return password?.replace(/^0+/, "");
  };

  const getOptional = useCallback((produto: IOrderProduct) => {
    let _adicionais: IObservations[] = [];
    let _observacoes: IObservations[] = [];

    if (produto.observations) {
      produto.observations.forEach((it) => {
        if (
          it.options
            .filter((f) => f.description.length > 0)
            .some((op) => !op.productId)
        ) {
          const obs = {
            ...it,
            options: it.options.filter(
              (i) => i.description.length
            ),
          };
          if (obs?.options?.length) {
            _observacoes.push(obs);
          }
        } else {
          let nOpcoes: IOptions[] = [];
          for (const ito of it.options) {
            if (
              it.options.length > 0 &&
              !ito.isProductGeneralObservation &&
              !!ito?.productId
            ) {
              nOpcoes.push(ito);
            }
          }
          if (nOpcoes.length) {
            _adicionais.push({
              ...it,
              options: nOpcoes,
            });
          }
        }
      });
    }

    return {
      adicionais: _adicionais,
      observacoes: _observacoes,
    };
  }, []);

  const categories = useMemo(() => {
    const record = order.products.reduce(
      (agrupado: Record<string, IOrderProduct[]>, produto) => {
        const { category } = produto;
        if (agrupado[category ?? "Padrão"]) {
          agrupado[category ?? "Padrão"].push(produto);
        } else {
          agrupado[category ?? "Padrão"] = [produto];
        }
        return agrupado;
      },
      {}
    );

    const _categories: ICategoryProducts[] = [];

    Object.keys(record).forEach((key) => {
      _categories.push({
        category: key,
        products: record[key],
      });
    });

    return _categories;
  }, [order.products]);

  return (
    <div id="remoteLayoutPrinter">
      <div className="container-RemoteLayoutPrinter">
        <div className="localNameRemote">
          {
            <span className="textLocal">
              {kiosk.localName} -{" "}
              <span className="textPedido">{order.friendlyId}</span>
            </span>
          }
        </div>

        {kiosk.mustEnableManagementPassword && (
          <div className="productPassWordOrderRemote">
            <span className="textLabelSenha">
              {password === null || password === undefined || password === ""
                ? "SEM SENHA"
                : "SENHA"}
            </span>{" "}
            <span className="textSenha">
              {" "}
              {password === null || password === undefined || password === ""
                ? "Dirija-se ao balcão"
                : removerZerosEsquerda(password)}
            </span>
          </div>
        )}

        <div className="ordersItems-remoteLayoutPrinter">
          {categories.map((category, key) => (
            <div className="container-RemoteOrderItem" key={key}>
              {kiosk.printCategoryInProductName && (
                <div className="title-RemoteLayoutPrinter">
                  {category.category}
                </div>
              )}
              {category.products.map((product, index) => {
                const { adicionais, observacoes } = getOptional(product);

                const storeNameToDisplay =
                  previousStoreName !== product.storeName &&
                  catalog &&
                  catalog?.stores?.length > 1
                    ? product.storeName
                    : null;
                previousStoreName = product.storeName ?? "";

                return (
                  <div className="content-OrderItem" key={index}>
                    {storeNameToDisplay && (
                      <div className="name-storeName-RemoteLayoutPrinter">
                        {storeNameToDisplay}:
                      </div>
                    )}
                    <div className="ordersItem-RemoteLayoutPrinter">
                      <div className="text-ordersItem-RemoteLayoutPrinter">
                        <div className="name-ordersItem-RemoteLayoutPrinter">
                          {product?.quantity && product.quantity > 0 && (
                            <span className="name-remote-product">
                              {product.quantity}x
                            </span>
                          )}
                          <span className="name-remote-product">
                            {product.name}
                          </span>
                        </div>
                        <div className="price-ordersItem-RemoteLayoutPrinter">
                          <span className="name-remote-product-price">
                            R$
                            {product.value.toFixed(2).replace(".", ",")}
                          </span>
                        </div>
                      </div>
                      {(adicionais.length > 0 || observacoes.length > 0) && (
                        <div className="composition-ordersItem-RemoteLayoutPrinter">
                          {adicionais.length > 0 && (
                            <div className="options-ordersItem-RemoteLayoutPrinter">
                              {adicionais.map((opcaoDoConsumidor) => (
                                <div className="optionConsumer-ordersItem-RemoteLayoutPrinter">
                                  <div className="descriptionOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                    {opcaoDoConsumidor.description}:
                                  </div>
                                  <div className="optionsItemsOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                    {opcaoDoConsumidor.options.map(
                                      (option) => (
                                        <div className="optionItemOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                          <div>
                                            {option.quantity}x{" "}
                                            {option.description}
                                          </div>
                                          <div>
                                            R$
                                            {option.productValue
                                              .toFixed(2)
                                              .replace(".", ",")}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          <>
                            {observacoes.map((op) => (
                              <div>
                                <div className="descricao">
                                  {op.description}:
                                </div>
                                <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                  {op.options.map((opcao) => (
                                    <div className="option">
                                      <div>{opcao.description}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          {order.orderObservation && (
            <div className="date-RemoteLayoutPrinter">{order.orderObservation}</div>
          )}
          <div className="amount-RemoteLayoutPrinter">
            <div>Total:</div>{" "}
            <div>R${order.total.toFixed(2).replace(".", ",")}</div>
          </div>
          <div className="remote-layout-dotted-separator" />
          {order?.client?.table && (
            <div className="date-RemoteLayoutPrinter">
              Mesa: {order.client.table}
            </div>
          )}
          {order?.client?.name && (
            <div className="date-RemoteLayoutPrinter">
              Cliente: {order.client.name}
            </div>
          )}

          <div className="date-RemoteLayoutPrinter">
            {toInputDateString(order?.orderDate ?? new Date())}
          </div>
          <div className="date-RemoteLayoutPrinter">{kiosk.kioskName}</div>
        </div>
      </div>
    </div>
  );
};
