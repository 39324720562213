import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { IOrder } from "modules/order/domain/models/IOrder";
import { v4 } from "uuid";

export const CreatePrintWalletRechargeUseCase = (
  balance: number,
  orderId: string,
  order: IOrder,
  walletId: string,
): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  const orderToPrint: IPrintOrder = {
    createdAt: new Date(order.createdAt),
    id: v4(),
    orderId: orderId,
    submitedAt: new Date(),
    data: {
      balance: balance,
      orderId: orderId,
      order: order,
      walletId: walletId
    },
    type: PrintOrderType.WALLET_RECHARGE,
    printType: PrintType.NONE,
  };

  ordersToPrint.push(orderToPrint);

  return ordersToPrint;
};
