import React, { FC, useCallback } from "react";
import "./PapelDeParedePlayer.css";
import { useKiosk } from "../../../../application/contexts/kiosk/KioskContext";
import { useHistory } from "react-router-dom";
import weighingMachineBG from "assets/images/bg-weighing-machine.png";
import weighingMachine from "assets/images/weighing.gif";
import enviroment from "corss-cutting/enviroment";

interface ImagemSliderProps {
  weighing?: boolean
}

interface ImagemSliderProps { }
export const PapelDeParedePlayer: FC<ImagemSliderProps> = (props) => {

  const { kiosk } = useKiosk();
  const history = useHistory();
  var countClick = 0;

  const onClickLogo = useCallback(() => {
    let timer: NodeJS.Timer | null = null;
    console.log(countClick);
    if (countClick === 0) {
      timer = setTimeout(() => {
        if (countClick < 2) {
          fetch("//google.com", {
            mode: "no-cors",
          })
            .then(() => {
              window.location.reload();
            })
            .catch((error) => {
              console.log("no internet connection: ", error);
            });
        }
        console.log("count", countClick);
        countClick = 0;
      }, 5000);
    } else if (countClick >= 4) {
      if (timer) {
        clearTimeout(timer);
      }
      history.push("/admin");
    }
    countClick++;

    return () => {
      if (timer) {
        console.log("clear");
        clearTimeout(timer);
      }
    };
  }, [countClick, history]);

  return (
    <div className="papelDeParedePalyer">

      <div className="top-papelDeParedePlayer">
        <div 
          className="logoMeep-papelDeParedePlayer" 
          onClick={onClickLogo} 
          data-custom-id="logo-button"
          data-custom-description="Hide logo button"
        />
      </div>
      {
        props.weighing ?
          <>
            <img
              id="player-papelDeParedePlayer"
              src={weighingMachineBG}
              alt="papel de Parede"
            ></img>
            <img
              className="weighing-gif-papelDeParedePlayer"
              src={weighingMachine}
              alt="papel de Parede"
            ></img>

          </> :
          (((kiosk?.profilerNamePDV && kiosk?.wallpaperImageId) || kiosk?.wallpaperImageUrl) && (
            <img
            id="player-papelDeParedePlayer"
              src={(kiosk?.profilerNamePDV && kiosk?.wallpaperImageId) ? `${process.env.REACT_APP_URL_IMAGEM}wallpapers/${kiosk.wallpaperImageId}.png` : kiosk?.wallpaperImageUrl}
              alt="papel de Parede"
            ></img>
          ))
      }

      <div className="children-papelDeParedePlayer">{props.children}</div>
      <div style={{ position: "fixed", color: "#fff", fontSize: 12, textShadow: "1px 1px #000", bottom: 6, left: 6, opacity: 0.8 }}>{enviroment.appVersion}</div>
    </div>
  );
};
