import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import React, { createContext, ReactNode, useCallback, useContext, useEffect } from "react";
import enviroment from "corss-cutting/enviroment";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";

//appi-prd-totem-app

const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  dataTags: {
    customDataPrefix: "data-custom-",
    captureAllMetaDataContent: true,
    useDefaultContentNameOrId: true,
  },
  autoCapture: true,
};

const connectionString = enviroment.appInsightsConString || '';
let appInsights: ApplicationInsights | null = null;

if (connectionString) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}


interface AppInsightsContext {
  addLog: (log: string, data: any) => void;
}

const AppInsightsContext = createContext<AppInsightsContext>({} as AppInsightsContext);

interface AppInsightsProviderProps {
  kiosk: Kiosk | null;
  children: ReactNode;
}

let globalAddLog: AppInsightsContext['addLog'];

export const getGlobalAddLog = () => globalAddLog;

export const AppInsightsProvider: React.FC<AppInsightsProviderProps> = ({kiosk, children }) => {

  const addLog = useCallback((log: string, data: any) => {
    appInsights?.trackEvent({
      name: log,
      properties: {
        version: enviroment.appVersion,
        localId: kiosk?.localId,
        local: kiosk?.localName,
        patrimonio: kiosk?.kioskName,
        ...data,
      },
    });
  },[kiosk]);

  globalAddLog = addLog;

  useEffect(() => {
    appInsights?.addTelemetryInitializer((env:ITelemetryItem) => {
      env.tags = env.tags || [];
      env.tags["ai.cloud.role"] = "MeepTotem";
      env.data = env.data || {};
      env.data["appName"] = "Totem";
      env.data["appVersion"] = enviroment.appVersion;
      env.data["local"] = kiosk?.localName;
      env.data["localId"] = kiosk?.localId;
      env.data["patrimonio"] = kiosk?.kioskName;
    });
  }, [kiosk])
  

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      let target = event.target as HTMLElement;
      
      while (target && !target.getAttribute("data-custom-id")) {
        target = target.parentElement as HTMLElement;
      }
      
      if (target) {
        const id = target.getAttribute("data-custom-id");
        const description = target.getAttribute("data-custom-description");
        const info = target.getAttribute("data-custom-info");
        
        if (id && description) {
          addLog('Click', {
            version: enviroment.appVersion,
            localId: kiosk?.localId,
            local: kiosk?.localName,
            patrimonio: kiosk?.kioskName,
            id,
            description,
            info,
            tagName: target.tagName,
            baseURI: target.baseURI,
          });
        }
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [addLog, kiosk]);

  return (
    <AppInsightsContext.Provider value={{ addLog }}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export const useAppInsights = (): AppInsightsContext => {
  const context = useContext(AppInsightsContext);
  return context;
};
