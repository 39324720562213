import { IObservations } from "components/layoutsPrinter/IOrderData";
import { IPrintOrder } from "components/layoutsPrinter/IPrintOrder";
import { TransactionTypeLabel } from "domains/order/agreggates/payment/transactionType";
import { Ticket } from "domains/order/agreggates/ticket/ticket";

export const parsePrintTicket = (printOrder: IPrintOrder, totem: string) => {
  const ticket: Ticket = {
    card: printOrder.data?.transaction?.FinalCartao ?? "",
    categoria: "",
    codigoBarras: printOrder.data.product.ticketId,
    dataVenda: new Date(),
    documentName: printOrder.data.product.name,
    fichaAtual: printOrder.data.index,
    id: printOrder.data.product.ticketId,
    paymentType: printOrder.data.paymentType
      ? TransactionTypeLabel.get(printOrder.data.paymentType) ?? ""
      : "",
    produto: printOrder.data.product.name,
    totalFichas: printOrder.data.products.length,
    valor: printOrder.data.product.value,
    terminal: totem,
    notes: printOrder.data.product.observations.map((it: IObservations) => ({
      description: it.description,
      options: it.options.map((o) => o.description),
    })),
    orderFriendlyId: printOrder.data.orderCode,
  };

  return ticket;
};
