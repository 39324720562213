import { SystemType } from "domains/kiosk/aggregates/Kiosk";
import { IOrderData } from "./IOrderData";
import { INfcePrintOrderData } from "./INfcePrintOrderData";
import { ICpfRegisterOrderData } from "./ICpfRegisterOrderData";
import { IOfflineRechargeOrderData } from "./IOfflineRechargeOrderData";
import { IWalletReceiptOrderData } from "./IWalletReceiptOrderData";
import { ITicketPrintOrderData } from "./ITicketPrintOrderData";
import { IWalletRechargeOrderData } from "./IWalletRechargeOrderData";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { IPinpadReceiptOrderData } from "./IPinpadReceiptOrderData";

export enum SpoolerStatus {
  standby = 0,
  isPrinting,
  error,
  stoped,
}

export interface IPrintOrderBase {
  createdAt: Date;
  id: string;
  orderId?: string;
  submitedAt?: Date;
  systemType?: SystemType;
  ticketId?: string;
  reprintId?: string;
  error?: string;
}

export enum PrintOrderStatus {
  STANDBY = 0,
  PENDING = 1,
  PRINTING = 3,
  PRINTED = 4,
  ERROR = 5,
  REMOTE_ERROR = 6,
}

export enum PrintOrderType {
  ORDER = 1,
  REMOTE_PRINT = 2,
  NFCE = 3,
  RESERVE_TOUR = 4,
  CPF_REGISTER = 5,
  OFFLINE_RECHARGE = 6,
  WALLET_RECEIPT = 7,
  WALLET_RECHARGE = 8,
  PINPAD_RECEIPT = 9,
}

type IPrintOrdersResume = {
  printType: PrintType.RESUME;
  type: PrintOrderType.ORDER;
  data: IOrderData;
} & IPrintOrderBase;

type IPrintOrdersGroupedResume = {
  printType: PrintType.GROUPED_RESUME;
  type: PrintOrderType.ORDER;
  data: any; //TODO: Criar layout de pedido agrupado
} & IPrintOrderBase;

type IPrintOrdersTicket = {
  printType: PrintType.TICKET | PrintType.SIMPLE_RESUME;
  type: PrintOrderType.ORDER;
  data: ITicketPrintOrderData;
} & IPrintOrderBase;

type IPrintOrderRemotePrint = {
  printType: PrintType.NONE;
  type: PrintOrderType.REMOTE_PRINT;
  data: IOrderData;
  addressLocalServer?: string;
} & IPrintOrderBase;

type IPrintNfceOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.NFCE;
  data: INfcePrintOrderData;
} & IPrintOrderBase;

type IPrintCpfRegisterOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.CPF_REGISTER;
  data: ICpfRegisterOrderData;
} & IPrintOrderBase;

type IPrintOfflineRechargeOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.OFFLINE_RECHARGE;
  data: IOfflineRechargeOrderData;
} & IPrintOrderBase;

type IPrintWalletReceiptOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.WALLET_RECEIPT;
  data: IWalletReceiptOrderData;
} & IPrintOrderBase;

type IPrintWalletRechargeOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.WALLET_RECHARGE;
  data: IWalletRechargeOrderData;
} & IPrintOrderBase;

type IPrintPinpadReceiptOrder = {
  printType: PrintType.NONE;
  type: PrintOrderType.PINPAD_RECEIPT;
  data: IPinpadReceiptOrderData;
} & IPrintOrderBase;

export type IPrintOrder =
  | IPrintOrdersResume
  | IPrintOrdersGroupedResume
  | IPrintOrdersTicket
  | IPrintOrderRemotePrint
  | IPrintNfceOrder
  | IPrintCpfRegisterOrder
  | IPrintOfflineRechargeOrder
  | IPrintWalletReceiptOrder
  | IPrintWalletRechargeOrder
  | IPrintPinpadReceiptOrder;
