import React, { FC, useCallback } from 'react'
import { OrderLog } from '../../../../domains/order/agreggates/order/Order';
import moment from 'moment';
import "./OrderLogItem.css"
import { Grid, IconButton, Icon } from '@material-ui/core';

interface OrderLogItemProps {
    orderLog: OrderLog,
    onClickPreview?: (order: OrderLog) => void
}
export const OrderLogItem: FC<OrderLogItemProps> = ({ orderLog, onClickPreview, }) => {

    const _onClickPreview = useCallback(
        () => {
            if (onClickPreview) {
                onClickPreview(orderLog);
            }
        },
        [onClickPreview, orderLog]
    )

    if (!orderLog?.friendlyId) {
        return null;
    }

    return (
        <div className="container-order-log-item">
            <Grid sm={12} container className="content-order-log-item" style={{ borderBottom: 1 }}>

                <Grid sm={2} item className="horario-order-log-item">
                    {orderLog.friendlyId}
                </Grid>
                <Grid sm={4} item className="horario-order-log-item">
                    {moment(orderLog.createdAt).format("DD/MM/YYYY [as] HH:mm:ss")}
                </Grid>
                <Grid sm={1} item className="quantidade-order-log-item">
                    {orderLog.orderItems?.length}
                </Grid>
                <Grid sm={2} item className="total-order-log-item">
                    R${(orderLog?.totalValue ?? 0).toFixed(2).replace(".", ",")}
                </Grid>
                <Grid sm={3} item className="total-order-log-item">
                    <IconButton onClick={_onClickPreview}><Icon>search</Icon></IconButton>
                </Grid>

            </Grid>
        </div >
    )
}
