import { Drawer } from "@material-ui/core";
import { PrinterStore, usePrinterStore } from "application/contexts/printer/PrinterStore";
import { CreatePrintNfceUseCase } from "modules/order/application/useCases/CreatePrintNfceUseCase";
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useKiosk } from "../../../../application/contexts/kiosk/KioskContext";
import { EletronicInvoiceTicket } from "../../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import "./ModalNotaFiscal.css";
interface IModalNotaFiscal { }
export interface IModalNotaFiscalRef {
  print: (notaFiscal: EletronicInvoiceTicket[]) => void;
  close: () => void;
}

export const ModalNotaFiscal = forwardRef<IModalNotaFiscalRef, IModalNotaFiscal>((props, ref) => {
  const { kiosk } = useKiosk();
  const [open, setOpen] = useState(false);
  const [nota, setNota] = useState<EletronicInvoiceTicket[] | null>(null);

  const [addOrdersToPrintQueue] = usePrinterStore(
      useShallow((state: PrinterStore) => [state.addOrders])
  );

  const print = useCallback((notaFiscal: EletronicInvoiceTicket[]) => {
    setNota(notaFiscal);
    setOpen(true);
  }, []);


  const onPressClose = useCallback(() => {
    setOpen(false);
    setNota(null);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      print: print,
      close: onPressClose,
    }),
    [onPressClose, print]
  );

  const onPressImprimir = useCallback(() => {
    if (nota) {
      onPressClose();
      addOrdersToPrintQueue(
        CreatePrintNfceUseCase(nota)
      );
    }
  }, [nota, onPressClose, addOrdersToPrintQueue]);

  return (
    <Drawer style={{ zIndex: 50000 }} PaperProps={{ id: "modalPrintNota" }} hideBackdrop anchor="bottom" open={open}>
      <div className="container">
        <div className="text">Deseja imprimir nota fiscal?</div>
        <div className="botoes">
          <div className="botao" style={{ backgroundColor: "#f55" }} onClick={onPressClose}>
            NÃO
          </div>
          <div className="botao" style={{ backgroundColor: "#27b" }} onClick={onPressImprimir}>
            SIM
          </div>
        </div>
      </div>
    </Drawer>
  );
});
