import { IPrintOrder, SpoolerStatus } from 'components/layoutsPrinter/IPrintOrder';
import {create} from 'zustand';

export type PrinterStore = {
  ordersToPrint: IPrintOrder[];
  spoolerStatus: SpoolerStatus;
  addOrders: (printOrders: IPrintOrder[]) => void;
  removeOrder: (printOrderId: string) => void;
  setSpoolerStatus: (status: SpoolerStatus) => void;
  getOrderToPrint: () => IPrintOrder;
};

export const usePrinterStore = create<PrinterStore>((set, get) => ({
  ordersToPrint: [],
  spoolerStatus: SpoolerStatus.standby,
  setSpoolerStatus: (status: SpoolerStatus) => set({spoolerStatus: status}),
  addOrders: printOrders => {
    set((state: PrinterStore) => {
      const ordersToPrint = [...state.ordersToPrint, ...printOrders];
      return {
        ordersToPrint,
      }
    });
  },
  getOrderToPrint: () => {
    const { ordersToPrint } = get();

    if (ordersToPrint.length === 0) {
      throw new Error('Nenhum pedido para ser impresso.');
    }

    const [firstOrder, ..._remainingOrders] = ordersToPrint;

    return firstOrder;
  },
  removeOrder: (printOrderId: string) =>
    set((state: PrinterStore) => ({
      ordersToPrint: state.ordersToPrint.filter(order => order.id !== printOrderId),
    })),
}));
