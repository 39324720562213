import Backdrop from '@material-ui/core/Backdrop';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';
import { ToastContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import meepLoadingGif from '../../../assets/lottie/meepLoadingGif.gif';
import { FullscreenToast, IFullScreenToastConfig } from '../../../components/sucessModal/FullscreenToast';

import "./UIContext.css";

interface IUIcontext {
    toast: (message: string, type: "error" | "success" | "warning", onClose?: () => void) => void,
    showLoading: () => void,
    hideLoading: () => void,
    toastFullscreen: (message: string | JSX.Element, type: "error" | "success" | "warning", config?: IFullScreenToastConfig) => void,
    transitionPageAnimation: (CB: () => void) => void
}

const UIContext = createContext<IUIcontext>({} as IUIcontext)



export const UIProvider: FC = ({ children }) => {

    const [loading, setloading] = useState(false);
    const [fullscrenToast, setfullscrenToast] = useState<IFullScreenToastConfig | null>(null);
    const [transitionPage, settransitionPAge] = useState(false)

    const toast = (message: string, type: "error" | "success" | "warning", onClose?: () => void) => {
        toastify(message, {
            type: type,
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose,
            style: { fontSize: 24, padding: 16 }
        });
    }

    const toastFullscreen = useCallback((message: string | JSX.Element, type: "error" | "success" | "warning", config?: IFullScreenToastConfig) => {
        console.log("TOAST FULL")
        setfullscrenToast({
            message,
            type: type,
            time: config?.time ?? 5000,
            color: config?.color,
            buttonText: config?.buttonText,
            onClickButton: config?.onClickButton,
            icon: config?.icon,
            iconSize: config?.iconSize,
            background: config?.background,
            onClose: () => {
                setfullscrenToast(null);
                if (config?.onClose) config?.onClose()
            }
        });
    }, [])

    const showLoading = useCallback(() => {
        setloading(true)
    }, [])
    const hideLoading = useCallback(() => {
        setloading(false)
    }, [])


    const transitionPageAnimation = useCallback((CB: () => void) => {
        settransitionPAge(true)
        const time1 = setTimeout(() => {
            CB()
        }, 0);
        const time = setTimeout(() => {
            settransitionPAge(false);
        }, 2000);

    }, [])

    return (
        <UIContext.Provider value={{ toast, showLoading, hideLoading: hideLoading, toastFullscreen, transitionPageAnimation }}>
            {children}
            <FullscreenToast
                config={fullscrenToast}
            />
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Backdrop style={{ zIndex: 2500 }} open={loading}>
                <div style={{ margin: "auto", zIndex: 1500 }} >
                    <img src={meepLoadingGif} alt="" width={300} height={300} />
                </div>
            </Backdrop>

            {transitionPage && <div className="transition-page">
                <div className="transition-logo">
                </div>
            </div>}

        </UIContext.Provider>
    )
}


export const useUi = () => {
    const context = useContext(UIContext);
    return context
}