import React, { useState, useCallback } from 'react'
import { TextField, MenuItem, Grid, Container, Paper, Button } from '@material-ui/core';
import { useAdmin } from '../../../application/contexts/admin/adminContext'
import { AuthKeyboard } from "../authKeyboard/AuthKeyboard"
export const AuthOperator = () => {
    const { operators, setOperator } = useAdmin()
    const [operatorId, setoperatorId] = useState("")
    const [passwordOk, setpasswordOk] = useState(false)


    const onPressLogin = useCallback(() => {

        if (passwordOk) {
            setOperator(operatorId)
        }

    }, [passwordOk, setOperator, operatorId])

    return (
        <Container maxWidth="sm" style={{ padding: 32, justifyContent: "center", alignItems: "center" }} >
            <Paper style={{ padding: 32 }}>
                <h1>OPERADOR</h1>

                <Grid sm={12} spacing={2} container>
                    <Grid sm={12} item>
                        <TextField
                            fullWidth
                            select
                            label="Operador"
                            onChange={(e) => setoperatorId(e.target.value)}
                            name={"operator"}
                            variant="outlined">
                            {
                                operators.length ? operators.map(operator => <MenuItem value={operator.id}>{operator.name}</MenuItem>) : <MenuItem>Aguarde...</MenuItem>
                            }
                        </TextField>
                    </Grid>
                    {operatorId &&
                        <Grid sm={12} item>
                            <AuthKeyboard
                                onValidate={(passwordOk) => { setpasswordOk(passwordOk) }}
                                password={operators.find(operator => operator.id === operatorId)?.password ?? "xxx"}
                            >

                            </AuthKeyboard>
                        </Grid>
                    }
                    <Grid sm={12} item>
                        <Button fullWidth onClick={onPressLogin} variant="contained" size="large">LOGIN</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>

    )
}
