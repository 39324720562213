import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { v4 } from "uuid";

export const CreatePrintCpfRegisterUseCase = (
  id: string,
  name: string,
  cpf: string,
  message: string,
): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  const orderToPrint: IPrintOrder = {
    createdAt: new Date(),
    id: v4(),
    orderId: id,
    submitedAt: new Date(),
    data: {
      name: name,
      cpf: cpf,
      message: message
    },
    type: PrintOrderType.CPF_REGISTER,
    printType: PrintType.NONE,
  };

  ordersToPrint.push(orderToPrint);

  return ordersToPrint;
};
