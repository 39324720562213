import { format } from "date-fns";
import QRCode from "qrcode.react";
import React, { FC } from "react";
import { formatCNPJ, hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import "./Nfce.css";
import { INfcePrintOrderData } from "../INfcePrintOrderData";

interface NfceProps {
  order: INfcePrintOrderData;
  kiosk: Kiosk;
}

export const Nfce: FC<NfceProps> = ({
  order,
  kiosk,
}) => {
  const serviceFee = order.itens.find((it) => it.isServiceFee);
  return (
    <div id="invoiceLayoutPrinter">
      <div className="title">{kiosk.localName}</div>
      <div className="sub-title">{formatCNPJ(kiosk.document)}</div>
      <div className="sub-title">{order.company}</div>

      <div className="invoice-divisor" />

      <div className="info">
        DANFE NFC-e
        <br />
        Documento auxiliar da Nota Fiscal do Consumidor eletrônica
      </div>

      <div className="invoice-divisor" />

      <div className="info">
        Consulte no SEFAZ do seu estado através do
        <br />
        QRCode ou utilize o código
      </div>
      <QRCode className="qrCode" size={200} value={order.danfe} />
      <div className="info-b">{order.key}</div>

      <div className="invoice-divisor" />

      <div className="sub-title">
        Produtos
      </div>

      <div className="products">
        <div>Nome</div>
        <div>Qtd.</div>
        <div>Valor Un.</div>
        <div>Total</div>
      </div>

      {order.itens
        .filter((it) => !it.isServiceFee)
        .map((item, key) => (
          <div key={key} className="products">
            <div>{item.name}</div>
            <div>{item.quantity}</div>
            <div>R${(item?.value ?? 0).toFixed(2).replace(".", ",")}</div>
            <div>
              R$
              {((item?.value ?? 0) * item.quantity)
                .toFixed(2)
                .replace(".", ",")}
            </div>
          </div>
        ))}

      {serviceFee && (
        <div className="item">
          <div>Taxa de serviço</div>
          <div>R${serviceFee.value.toFixed(2).replace(".", ",")}</div>
        </div>
      )}

      <div className="item-b">
        <div>Valor total</div>
        <div>
          <b>R${order.totalValue.toFixed(2).replace(".", ",")}</b>
        </div>
      </div>

      {order.infoInteresseContribuinte && (
        <>
          <div className="invoice-divisor" />
          <div className="info">
            {order.infoInteresseContribuinte}
          </div>
        </>
      )}

      <div className="invoice-divisor" />

      <div className="item">
        <div>Consumidor</div>
        <div>{order.client ? hideCpf(order.client) : "Não informado"}</div>
      </div>

      <div className="item">
        <div>NFC-e</div>
        <div>{`${order.numbering} • Série ${order.serie}`}</div>
      </div>

      <div className="item">
        <div>Data</div>
        <div>
          {format(new Date(order.emissionDate), "dd/MM/yyyy '•' HH:mm")}
        </div>
      </div>


      {order.infoInteresseFisco && (
        <>
          <div className="invoice-divisor" />
          <div className="info">
            {order.infoInteresseFisco}
          </div>
        </>
      )}
    </div>
  );
};
