import { IPinpadReceiptOrderData } from "components/layoutsPrinter/IPinpadReceiptOrderData";
import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { IWalletReceiptOrderData } from "components/layoutsPrinter/IWalletReceiptOrderData";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { Payment } from "domains/order/agreggates/payment/payment";
import { TransactionType } from "modules/order/domain/models/Payment";
import { v4 } from "uuid";

export const CreatePrintReceiptUseCase = (receipt: Payment, orderDate?: string, friendlyId?: string): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  if (
    receipt.paymentMethod === TransactionType.wallet &&
    receipt.walletReceipt
  ) {
    const _receipt: IWalletReceiptOrderData = {
      ...receipt.walletReceipt,
      orderDate: orderDate ?? new Date().toISOString(),
      friendlyId: friendlyId ?? ''
    };

    const printOrder: IPrintOrder = {
      createdAt: new Date(receipt.FinancialTransactionModel.DataCriacao),
      data: _receipt,
      id: v4(),
      printType: PrintType.NONE,
      type: PrintOrderType.WALLET_RECEIPT,
    };

    ordersToPrint.push(printOrder);
  } else {
    const _receipt: IPinpadReceiptOrderData = {
      receipt: receipt.comprovanteTEF,
    };

    const printOrder: IPrintOrder = {
      createdAt: new Date(receipt.FinancialTransactionModel.DataCriacao),
      data: _receipt,
      id: v4(),
      printType: PrintType.NONE,
      type: PrintOrderType.PINPAD_RECEIPT,
    };

    ordersToPrint.push(printOrder);
  }
  return ordersToPrint;
};
