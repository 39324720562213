import { AppInsightsProvider } from "Infrastructure/repositories/appInsights/AppInsights";
import { IdleProvider } from "application/contexts/idle/IdleContext";
import NewPrinterProvider from "application/contexts/printer/PrinterProvider";
import { ThemeProvider } from "application/contexts/theme/ThemeContext";
import WeighingMachineProvider from "application/contexts/weighingMachine/WeighingMachine";
import { OfflineRecharge } from "areas/cashless/offlineRecharge/OfflineRecharge";
import WalletPage from "areas/wallet/WalletPage";
import RequestDocument from "components/requestDocument/RequestDocument";
import OrderProvider, {
  IOrderProviderRef,
} from "modules/order/presentation/OrderProvider";
import React, { FC, memo, useRef } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { AdminProvider } from "./application/contexts/admin/adminContext";
import { AuthProvider } from "./application/contexts/auth/AuthContext";
import { CardReaderProvider } from "./application/contexts/cardReader/CardReaderContext";
import { CatalogProvider } from "./application/contexts/catalog/CatalogContext";
import {
  KioskConsumer,
  KioskProvider,
} from "./application/contexts/kiosk/KioskContext";
import { SessionProvider } from "./application/contexts/session/SessionContext";
import { UIProvider } from "./application/contexts/ui/UIContext";
import { Admin } from "./areas/admin/Admin";
import { BarCode } from "./areas/barCode/BarCode";
import { Cashless } from "./areas/cashless/Cashless";
import { Catalog } from "./areas/catalog/Catalog";
import StorePage from "./areas/catalog/pages/storePage/StorePage";
import { StoresListPage } from "./areas/catalog/pages/storesListPageChurch/StoresListPage";
import { Inicio } from "./areas/inicio/Inicio";
import WeighingMachinePage from "./areas/weighingMachine/WeighingMachinePage";
import { SyncTimer } from "./components/syncTimer/SyncTimer";
import "./cores.css";

const App: FC = memo(() => {
  const orderProvider = useRef<IOrderProviderRef>(null);

  return (
    <div className="App">
      <UIProvider>
        <BrowserRouter>
          <AuthProvider>
            <KioskProvider>
              <KioskConsumer>
                {({ kiosk }) => (
                  <AppInsightsProvider kiosk={kiosk}>
                    <IdleProvider kiosk={kiosk}>
                      <ThemeProvider>
                        <SessionProvider>
                          <CardReaderProvider>
                            <CatalogProvider>
                              <NewPrinterProvider kiosk={kiosk} />
                              <OrderProvider ref={orderProvider} />
                              <WeighingMachineProvider>
                                <SyncTimer />
                                {
                                  <Switch>
                                    <>
                                      <Route
                                        exact
                                        path="/"
                                        component={Inicio}
                                      />
                                      <Route
                                        path="/barcode"
                                        component={BarCode}
                                      />
                                      <Route path="/weighing">
                                        <WeighingMachinePage />
                                      </Route>
                                      <Route path="/catalog">
                                        <Catalog>
                                          <Switch>
                                            <Route exact path={"/catalog"}>
                                              {StoresListPage && (
                                                // <StoresListPage />
                                                <StoresListPage />
                                              )}
                                            </Route>
                                            <Route
                                              path={"/catalog/store/:storeId"}
                                            >
                                              <StorePage />
                                            </Route>
                                          </Switch>
                                        </Catalog>
                                      </Route>
                                      <Route
                                        path="/cashless/:type"
                                        component={() => (
                                          <Cashless
                                            orderProvider={orderProvider}
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/offlineRecharge"
                                        component={() => (
                                          <OfflineRecharge
                                            orderProvider={orderProvider}
                                          />
                                        )}
                                      />
                                      <Route
                                        path="/validateDocument"
                                        component={RequestDocument}
                                      />
                                      <Route path="/admin">
                                        <AdminProvider>
                                          <Admin></Admin>
                                        </AdminProvider>
                                      </Route>
                                      <Route path="/wallet">
                                        <WalletPage
                                          orderProvider={orderProvider}
                                        />
                                      </Route>

                                    </>
                                  </Switch>
                                }
                              </WeighingMachineProvider>
                            </CatalogProvider>
                          </CardReaderProvider>
                        </SessionProvider>
                      </ThemeProvider>
                    </IdleProvider>
                  </AppInsightsProvider>
                )}
              </KioskConsumer>
            </KioskProvider>
          </AuthProvider>
        </BrowserRouter>
      </UIProvider>
    </div>
  );
});

export default App;
