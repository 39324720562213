import { ICefSharpWeighingMachine } from "./Interfaces/ICefSharpWeighingMachine";

import { ICefSharp } from "./Interfaces/_ICefSharp";

declare const CefSharp: ICefSharp;
declare const weighingScaleService: ICefSharpWeighingMachine;
declare global {
  interface Window {
    setWeight: (weight: number) => void;
  }
}

export const CefSharpWeighingMachine = (): ICefSharpWeighingMachine => {
  const getValueFromWighingScale = async (port: string) => {
    await CefSharp.BindObjectAsync("weighingScaleService");
    weighingScaleService?.getValueFromWighingScale(port);
  };

  return { getValueFromWighingScale };
};
