import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { ILayoutPrinterHandler } from "components/layoutsPrinter/LayoutPrinter";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { IPrintRepository } from "domains/order/interfaces/repositories/iprintRespository";
import { RefObject } from "react";
import { GetError } from "utils/GetError";
import { parsePrintRemote } from "./parsePrintRemote";
import { parsePrintResume } from "./parsePrintResume";
import { parsePrintTicket } from "./parsePrintTicket";
import { EletronicInvoiceTicket } from "domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";

export const localPrintUseCase = async (
  layoutPrintRef: RefObject<ILayoutPrinterHandler>,
  printRepository: IPrintRepository,
  printOrder: IPrintOrder,
  kiosk: Kiosk
) => {
  let printFile: string | null = "";

  try {
    console.log("[LOCAL_PRINT_FILE][START_CAPTURE]", new Date().toISOString());
    printFile = await layoutPrintRef.current!.getPrintLayout(printOrder);
    console.log(
      "[LOCAL_PRINT_FILE][CAPTURE_SUCCESS]",
      new Date().toISOString()
    );
  } catch (error) {
    console.log("[LOCAL_PRINT_FILE][FALHA_AO_CAPTURAR_IMAGEM]", error);
    throw GetError(error, "localPrintUseCase", "LocalPrintUseCase");
  }

  switch (printOrder.type) {
    case PrintOrderType.ORDER:
      try {
        if (
          kiosk.oldPrinter ||
          kiosk.localId === "52d97f4e-5ad8-485f-9a18-85e622837359" // TODO: Remover id hardcoded
        ) {
          if (printOrder.printType === PrintType.TICKET) {
            // Old Printer
            // Ticket
            console.log("[LOCAL_PRINT_FILE][OLD_PRINTER_TICKET]");
            const result = await printRepository.printOrderAsync(
              parsePrintTicket(printOrder, kiosk?.kioskName ?? "Totem")
            );

            console.log("[LOCAL_PRINT_FILE]", { result });
            printFile = null;

            if (!result) {
              throw GetError(
                "Falha na impressão1",
                "localPrintUseCase",
                "LocalPrintUseCase"
              );
            }
            console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
          } else if (printOrder.printType === PrintType.RESUME) {
            console.log("[LOCAL_PRINT_FILE][OLD_PRINTER_RESUME]");
            // Old Printer
            // Resume
            const result = await printRepository.printResumeAsync(
              parsePrintResume(printOrder, kiosk, printFile)
            );

            console.log("[LOCAL_PRINT_FILE]", { result });
            printFile = null;

            if (!result) {
              throw GetError(
                "Falha na impressão2",
                "localPrintUseCase",
                "LocalPrintUseCase"
              );
            }
            console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
          }
        } else {
          // New Printer
          // Resume/Ticket
          console.log(
            "[LOCAL_PRINT_FILE][NEW_PRINTER][START_PRINT]",
            new Date().toISOString()
          );
          const result = await printRepository.printImage(printFile);
          console.log(
            "[LOCAL_PRINT_FILE][NEW_PRINTER][END_PRINT]",
            new Date().toISOString()
          );
          printFile = null;

          if (!result) {
            throw GetError(
              "Falha na impressão3",
              "localPrintUseCase",
              "LocalPrintUseCase"
            );
          }

          console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
        }
      } catch (error) {
        throw GetError(
          "Falha na impressão",
          "localPrintUseCase",
          "LocalPrintUseCase"
        );
      }
      break;

    case PrintOrderType.REMOTE_PRINT:
      try {
        if (printOrder.addressLocalServer) {
          console.log("[LOCAL_PRINT_FILE][REMOTE_PRINT]");
          // console.log('^^^^', printFile)
          printRepository.remotePrintAsync(
            printOrder.addressLocalServer,
            parsePrintRemote(printOrder, kiosk, printFile)
          );
        } else {
          console.log("endereço do servidor de impressao nao encontrado");
        }
      } catch (error) {
        console.log("error", { error });
      }
      break;
    case PrintOrderType.CPF_REGISTER:
    case PrintOrderType.OFFLINE_RECHARGE:
    case PrintOrderType.WALLET_RECEIPT:
    case PrintOrderType.WALLET_RECHARGE:
      try {
        console.log("[LOCAL_PRINT_FILE][BASE_64]");
        const result = await printRepository.printImage(printFile);
        console.log("[LOCAL_PRINT_FILE]", { result });
        printFile = null;

        if (!result) {
          throw GetError(
            "Falha na impressão",
            "localPrintUseCase",
            "LocalPrintUseCase"
          );
        }

        console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
      } catch (error) {}
      break;
    case PrintOrderType.NFCE:
      if (kiosk.newPrinter) {
        try {
          console.log("[LOCAL_PRINT_FILE][BASE_64]");
          const result = await printRepository.printImage(printFile);
          console.log("[LOCAL_PRINT_FILE]", { result });
          printFile = null;
  
          if (!result) {
            throw GetError(
              "Falha na impressão",
              "localPrintUseCase",
              "LocalPrintUseCase"
            );
          }
  
          console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
        } catch (error) {
          console.log({error})
        }
      } else {
        try {
          const ticket: EletronicInvoiceTicket = {
            companyDocument: printOrder.data.cnpj,
            danfe: printOrder.data.danfe,
            emissionDate: printOrder.data.emissionDate,
            itens: printOrder.data.itens,
            key: printOrder.data.key,
            mustPrintCompleteDANFE: printOrder.data.mustPrintCompleteDANFE,
            numbering: printOrder.data.numbering,
            protocol: printOrder.data.protocol,
            razaosocial: printOrder.data.company,
            serie: printOrder.data.serie,
            status: printOrder.data.status,
            totalValue: printOrder.data.totalValue,
            client: printOrder.data?.client ?? '',
            header: printOrder.data.header,
            text: printOrder.data.text,
            infoInteresseContribuinte: printOrder.data.infoInteresseContribuinte,
            infoInteresseFisco: printOrder.data.infoInteresseFisco
          }
          const status = await printRepository.printEletronicInvoiceAsync(ticket);
          if (status) {
            return { status: true, message: `NFCe ${ticket.key} impressa.` };
          } else {
            return { status: false, message: `NFCe ${ticket.key} não impressa.` };
          }
        } catch (error) {
          console.log({error})
        }
      }
      break;
    case PrintOrderType.PINPAD_RECEIPT:
      try {
        console.log("[LOCAL_PRINT_FILE][RECEIPT_STRING]");
        const result = await printRepository.printStringAsync(
          printOrder.data.receipt
        );
        console.log("[LOCAL_PRINT_FILE]", { result });
        printFile = null;

        if (!result) {
          throw GetError(
            "Falha na impressão",
            "localPrintUseCase",
            "LocalPrintUseCase"
          );
        }

        console.log("[LOCAL_PRINT_FILE][SUCCESS]", result);
      } catch (error) {}
      break;
  }
};
