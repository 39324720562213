import React, { useState, useCallback, useEffect } from 'react'
import { useAdmin } from '../../../application/contexts/admin/adminContext'
import { OrderLogItem } from './orderLogItem/OrderLogItem'
import { Container, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Drawer } from '@material-ui/core';
import { OrderLogItemPreview } from './orderLogItemPreview/OrderLogItemPreview';
import { OrderLog, RePrintOrderSyncObject } from '../../../domains/order/agreggates/order/Order';
import { AuthKeyboard } from '../authKeyboard/AuthKeyboard';
import { InputField } from '../../payment/inputField/InputField';
import { useUi } from '../../../application/contexts/ui/UIContext';
import "./OrderLogView.css"
import { AdminRepository } from '../../../Infrastructure/repositories/api/AdminRepository';
import { PaymentService } from '../../../domains/order/services/paymentService';
import { useKiosk } from 'application/contexts/kiosk/KioskContext';
import { PedidoPos, ProdutoPedidoPos } from 'Infrastructure/repositories/api/PedidoMeep';
import { PrinterStore, usePrinterStore } from 'application/contexts/printer/PrinterStore';
import { useShallow } from 'zustand/react/shallow';
import { CreatePrintOrderUseCase } from 'modules/order/application/useCases/CreatePrintOrderUseCase';
import { OrderRepositoryLocalStorage } from 'Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage';
import { v4 } from 'uuid';
import moment from 'moment';


//container
const paymentService = PaymentService();
const adminRepository = AdminRepository();

export const OrderLogView = () => {
    const { orderLog, onOrderLogReprinted, selectedOperator } = useAdmin();
    const { toastFullscreen, showLoading, hideLoading } = useUi()
    const { kiosk } = useKiosk();

    const [addOrdersToPrintQueue] = usePrinterStore(
        useShallow((state: PrinterStore) => [state.addOrders])
    );

    const [modalPreviewOpen, setmodalPreviewOpen] = useState(false);
    const [itemSelectedView, setitemSelectedView] = useState<OrderLog | null>(null);
    const [autenticated, setautenticated] = useState(false);
    const [confirmCancelOpen, setconfirmCancelOpen] = useState(false);
    const [itemSelectedCancel, setitemselectedCancel] = useState<OrderLog | null>(null);
    const [keyboardValues, setkeyboardValues] = useState<{ min: number, max: number, message: string } | null>(null)

    const onCloseModal = useCallback(
        () => {
            setmodalPreviewOpen(false);
            setitemSelectedView(null)
            onOrderLogReprinted();
        },
        [onOrderLogReprinted],
    )

    const onClickPreview = useCallback(
        (orderItem: OrderLog) => {
            setmodalPreviewOpen(true);
            setitemSelectedView(orderItem)
        },
        [],
    )

    const onClickPrintTicket = useCallback((pedidoPos: PedidoPos, produtoPedidoPos?: ProdutoPedidoPos) => {
        if (selectedOperator) {
            addOrdersToPrintQueue(
                CreatePrintOrderUseCase(pedidoPos, kiosk!, produtoPedidoPos?.ticketId)
            );

            const orderRepositoryLocalStorage = OrderRepositoryLocalStorage();
            const orderReprint: RePrintOrderSyncObject =
            {
                reimpressaoId: v4(),
                dataRealizacao: moment().utc().toDate(),
                ticketId: produtoPedidoPos?.ticketId ?? '',
                operadorId: selectedOperator.id,
                localClienteId: kiosk?.localId ?? "",
                equipamentoId: kiosk?.id ?? ""
            }

            produtoPedidoPos?.ticketId && orderRepositoryLocalStorage.updateRePrintTicket(produtoPedidoPos.ticketId, orderReprint);
        }
    }, [addOrdersToPrintQueue, kiosk, selectedOperator])

    const onCloseConfirmCancelModal = () => {
        setconfirmCancelOpen(false)
        setitemselectedCancel(null)
    }

    const onConfirmCancel = useCallback(async () => {
        if (autenticated && itemSelectedCancel) {
            if (itemSelectedCancel.payment?.paymentMethod) {
                try {
                    showLoading();

                    const value = await paymentService.cancelPaymentAsync(
                        itemSelectedCancel.totalValue,
                        itemSelectedCancel.payment?.paymentMethod,
                        itemSelectedCancel.payment?.FinancialTransactionModel.NumeroCupom,
                        itemSelectedCancel.createdAt)
                    if (value) {
                        if (itemSelectedCancel && selectedOperator) {
                            adminRepository.syncCancelPayment(itemSelectedCancel.id, selectedOperator?.id, selectedOperator?.password)
                        }
                        toastFullscreen("Estorno realizado com sucesso!", "success")
                    } else {
                        console.log("ERRO FALSE ON TRY");
                    }
                } catch (error) {
                    console.log("ERROR FALSE ON CATCH");
                } finally {
                    setkeyboardValues(null);
                    hideLoading();
                }

            }
            onCloseConfirmCancelModal()
        }
    }, [autenticated, hideLoading, itemSelectedCancel, selectedOperator, showLoading, toastFullscreen])


    useEffect(() => {
        window.showKeyboard = (min: number, max: number, message: string) => {
            console.log("min: ", min);
            console.log("max: ", max);
            console.log("message: ", message);
            hideLoading();
            setkeyboardValues({ min, max, message })
        };

    }, []);


    const isTicketPrinter = true;
    const saveLogs = useCallback(
      () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(orderLog)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${new Date().getTime()}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      },
      [orderLog],
    )
    

    return (
        <Container id="container-orderlog">
            <Drawer anchor="bottom" open={!!keyboardValues}>
                <div style={{ padding: 16 }}>
                    <InputField
                        keyboardType="numeric"
                        minInputLength={keyboardValues?.min}
                        maxInputLength={keyboardValues?.max}
                        description={keyboardValues?.message ?? ""}
                        onPressContinuar={(value) => {
                            window.keyboardValue = value ?? "";
                            setkeyboardValues(null)
                            showLoading();

                        }}
                        onPressCancel={() => {
                            setkeyboardValues(null);
                        }}

                        buttonField={{
                            confirm: "Confirmar",
                            cancel: "Confirmar",
                        }}

                    ></InputField>
                </div>
            </Drawer>

            <Grid sm={12} container className="content-order-log-item">
                <Grid sm={2} item className="horario-order-log-item" onClick={saveLogs}>
                    Id
                </Grid>
                <Grid sm={4} item className="horario-order-log-item">
                    Horário
                </Grid>
                <Grid sm={1} item className="quantidade-order-log-item">
                    Itens
                </Grid>
                <Grid sm={2} item className="total-order-log-item">
                    Preço
                </Grid>
                <Grid sm={3} item className="total-order-log-item">
                    Ações
                </Grid>
            </Grid>
            {kiosk && orderLog.map((orderLogItem, index) => (
                    orderLogItem && <OrderLogItem
                        key={index}
                        onClickPreview={onClickPreview}
                        orderLog={orderLogItem}
                    />))
            }


            <Dialog onClose={onCloseModal} open={modalPreviewOpen}>
                <>
                    {
                        itemSelectedView &&
                        <OrderLogItemPreview
                            orderLog={itemSelectedView}
                            onClose={onCloseModal}
                            isTicketPrinter={isTicketPrinter}
                            onClickPrintProdutoPosTicket={(produtoPedidoPos) => itemSelectedView?.pedidoPOS && onClickPrintTicket(itemSelectedView.pedidoPOS, produtoPedidoPos)}
                            onClickPrintPedidoPos={(pedidopos) => itemSelectedView?.pedidoPOS && onClickPrintTicket(itemSelectedView.pedidoPOS)}
                        />
                    }
                </>
            </Dialog>
            <Dialog onClose={onCloseModal} open={confirmCancelOpen}>
                <>
                    <DialogTitle> Digite sua senha novamente </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Atenção o pedido {itemSelectedCancel?.friendlyId ?? ""} será cancelado, e o pagamento R${itemSelectedCancel?.totalValue.toFixed(2).replace(".", ",")} será estornada ao cliente</DialogContentText>
                        {
                            selectedOperator &&
                            <AuthKeyboard password={selectedOperator.password} onValidate={(autenticated) => { setautenticated(autenticated) }} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseConfirmCancelModal}>Cancelar</Button>
                        <Button onClick={onConfirmCancel}>Confirmar</Button>
                    </DialogActions>
                </>
            </Dialog>
        </Container>
    )
}
