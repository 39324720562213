import React, { FC } from "react";
import styles from "./CpfRegister.module.scss";
import { hideCpf } from "utils/utils";
import { ICpfRegisterOrderData } from "../ICpfRegisterOrderData";

interface CpfRegister {
  order: ICpfRegisterOrderData;
}

const CpfRegister: FC<CpfRegister> = ({ order }) => (
  <div id={styles.CpfRegisterLayoutPrinter}>
    <div className={styles.container}>
      <div className="logo-ResumoLayoutPrinter"></div>
      <div className={styles.user}>
        <h1>Bem-vindo</h1>
        <div className={styles.item}>
          <h2>{order.name}</h2>
        </div>
        <div className={styles.item}>
          <h2>{hideCpf(order.cpf)}</h2>
        </div>
        <div className={styles.item}>
          <h2>{order.message}</h2>
        </div>
      </div>
    </div>
  </div>
);

export default CpfRegister;
