import {
  IOrderData,
  OrderStatusEnum,
} from "components/layoutsPrinter/IOrderData";
import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { OrderItem } from "domains/order/agreggates/order/OrderItem";
import { IPrintSector } from "Infrastructure/repositories/api/ConfigTotemMeep";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { v4 } from "uuid";

export const CreatePrintRemoteUseCase = (
  order: IOrder,
  pedidoPOS: IPedidoPosDTO,
  kiosk: Kiosk
): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  if (
    !kiosk?.configSymstem
      .flatMap((item) => item.enableRemotePrint)
      .find((item) => item)
  ) {
    // Impressão remota desabilitada
    return [];
  }

  if (
    // Não sei o que é esse oldPrinter, mas mantive a regra, não passa pelo agrupamento de impressão inteligente
    kiosk.oldPrinter ||
    kiosk.localId === "52d97f4e-5ad8-485f-9a18-85e622837359"
  ) {
    const orderToPrint: IOrderData = {
      acquirerType: pedidoPOS.tipoAdquirente,
      client: pedidoPOS.clienteEstabelecimentoObject,
      friendlyId:
        kiosk?.kioskName.replace(/\D/gm, "") +
        " " +
        pedidoPOS?.pedidoPOSId?.substring(0, 4),
      orderCode: pedidoPOS.codigoPedido,
      tag: pedidoPOS.clienteEstabelecimentoObject.tag ?? "",
      total: pedidoPOS.valorTotal,
      orderDate: pedidoPOS.dataPedido,
      orderObservation: pedidoPOS.observacaoPedido,
      orderPassword: pedidoPOS.codigoPainelSenha,
      paymentType: pedidoPOS.tipoPagamento,
      pedidoPOSId: pedidoPOS.pedidoPOSId,
      pedidoPOSPaiId: pedidoPOS.pedidoPOSPaiId,
      transaction: pedidoPOS.dadosTransacaoObject,
      orderStatus: OrderStatusEnum.Aprovado,
      products: order.orderItems.map((it) => ({
        id: it.id,
        name: it.name,
        observations:
          it.orderComposition?.map((i) => ({
            id: i.id,
            description: i.description,
            options: i.orderCompositionItems.map((j) => ({
              id: j.id,
              productId: j.productId,
              description: j.description,
              isChecked: false,
              quantity: j.quantity,
              productValue: j.price,
              quantityFree: j.quantityFree,
              currentFree: j.quantityFreeSelected,
            })),
          })) ?? [],
        ticketId: "",
        printerName: it.printerName,
        value: it.totalPrice,
      })),
    };

    const printOrder: IPrintOrder = {
      createdAt: new Date(order.createdAt),
      id: v4(),
      orderId: pedidoPOS.pedidoPOSId,
      submitedAt: new Date(),
      data: orderToPrint,
      type: PrintOrderType.REMOTE_PRINT,
      printType: PrintType.NONE,
      addressLocalServer: kiosk.addressLocalServer,
    };

    return [printOrder];
  } else {
    const groupedItems = groupItemsByProductIds(
      order,
      kiosk?.printingSectors ?? [],
      kiosk?.id ?? ""
    );

    for (const group of groupedItems) {
      const orderToPrint: IOrderData = {
        acquirerType: pedidoPOS.tipoAdquirente,
        client: pedidoPOS.clienteEstabelecimentoObject,
        friendlyId:
          kiosk?.kioskName.replace(/\D/gm, "") +
          " " +
          pedidoPOS?.pedidoPOSId?.substring(0, 4),
        orderCode: pedidoPOS.codigoPedido,
        tag: pedidoPOS.clienteEstabelecimentoObject.tag ?? "",
        total: pedidoPOS.valorTotal,
        orderDate: pedidoPOS.dataPedido,
        orderObservation: pedidoPOS.observacaoPedido,
        orderPassword: pedidoPOS.codigoPainelSenha,
        paymentType: pedidoPOS.tipoPagamento,
        pedidoPOSId: pedidoPOS.pedidoPOSId,
        pedidoPOSPaiId: pedidoPOS.pedidoPOSPaiId,
        transaction: pedidoPOS.dadosTransacaoObject,
        orderStatus: OrderStatusEnum.Aprovado,
        products: group.items.map((it) => ({
          id: it.id,
          name: it.name,
          observations:
            it.orderComposition?.map((i) => ({
              id: i.id,
              description: i.description,
              options: i.orderCompositionItems.map((j) => ({
                id: j.id,
                productId: j.productId,
                description: j.description,
                isChecked: false,
                quantity: j.quantity,
                productValue: j.price,
                quantityFree: j.quantityFree,
                currentFree: j.quantityFreeSelected,
                isProductGeneralObservation: j.isProductGeneralObservation,
              })),
            })) ?? [],
          ticketId: "",
          printerName: it.printerName,
          value: it.totalPrice,
        })),
      };

      const printOrder: IPrintOrder = {
        createdAt: new Date(order.createdAt),
        id: v4(),
        orderId: pedidoPOS.pedidoPOSId,
        submitedAt: new Date(),
        data: orderToPrint,
        type: PrintOrderType.REMOTE_PRINT,
        printType: PrintType.NONE,
        addressLocalServer: kiosk.addressLocalServer,
      };

      ordersToPrint.push(printOrder);
    }

    return ordersToPrint;
  }
};

const groupItemsByProductIds = (
  order: IOrder,
  printingSectorsContainer: IPrintSector[],
  totemId: string
): { printerName: string; items: OrderItem[] }[] => {
  const groupedItems: { [printerName: string]: OrderItem[] } = {};

  order.orderItems.forEach((item) => {
    const productId = item.productId;
    const printingSector = printingSectorsContainer.find(
      (sector) =>
        sector.productsIds.includes(productId) &&
        sector.totemsIds.includes(totemId)
    );

    const printerName = printingSector
      ? printingSector.printerName
      : item.printerName ?? "";

    if (!groupedItems[printerName]) {
      groupedItems[printerName] = [];
    }

    groupedItems[printerName].push(item);
  });
  return Object.entries(groupedItems)
    .map(([printerName, items]) => ({
      printerName,
      items,
    }))
    .filter((it) => it.printerName);
};
