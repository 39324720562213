import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { v4 } from "uuid";

export const CreatePrintOfflineRechargeUseCase = (
  newBalance: number,
  previousBalance: number,
  orderId: string,
  tag: string,
  createdAt: string,
  document?: string,
  name?: string,
): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  const orderToPrint: IPrintOrder = {
    createdAt: new Date(createdAt),
    id: v4(),
    orderId: orderId,
    submitedAt: new Date(),
    data: {
      newBalance: newBalance,
      orderId: orderId,
      previousBalance: previousBalance,
      tag: tag,
      document: document,
      name: name,
    },
    type: PrintOrderType.OFFLINE_RECHARGE,
    printType: PrintType.NONE,
  };

  ordersToPrint.push(orderToPrint);

  return ordersToPrint;
};
