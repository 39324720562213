import { Drawer, Icon, IconButton, Modal } from "@material-ui/core";
import { useCardReader } from "application/contexts/cardReader/CardReaderContext";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { TecladoVirtual } from "components/tecladoVirtual/TecladoVirtual";
import { SystemType } from "domains/kiosk/aggregates/Kiosk";
import { IOrder } from "modules/order/domain/models/IOrder";
import { ICardCashless } from "modules/payment/domain/models/ICashless";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createDefferedPromise } from "utils/DefferedPromise";
import styles from "./Cashless.module.scss";
import { useUi } from "application/contexts/ui/UIContext";
import { UpdateGroupDiscount } from "modules/payment/application/useCases/UpdateGroupDiscountUseCase";

export interface ICashlessReturn {
  cardData: ICardCashless,
  order: IOrder,
}

export interface ICashlessHandler {
  open(order: IOrder): Promise<ICashlessReturn>;
}

interface ICashlessProps {}

const UseCashless: React.ForwardRefRenderFunction<
  ICashlessHandler,
  ICashlessProps
> = ({}, ref) => {
  const deffered = useRef(createDefferedPromise<ICashlessReturn>());
  const { cardData, enableSensor, disableSensor, groupDiscounts } = useCardReader();
  const { kiosk } = useKiosk();
  const { toast } = useUi();

  
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [confirmarSenhaOpen, setConfirmarSenhaOpen] = useState(false);
  const [password, setPassword] = useState("");

  const posPaid = useMemo(
    () =>
      kiosk?.configSymstem.some(
        (it) => it.systemType === SystemType.pospayOrderPad
      ),
    [kiosk]
  );

  const open = useCallback((_order: IOrder) => {
    enableSensor();
    setOrder(_order);
    setVisible(true);
    deffered.current = createDefferedPromise<ICashlessReturn>();
    return deffered.current.promise;
  }, [enableSensor]);

  useImperativeHandle(ref, () => ({ open }));

  const onKeyPress = useCallback(
    (key: string) => {
      if (password.length < 4) {
        setPassword(password + key);
      }
    },
    [password]
  );
  const onClearPress = useCallback(() => {
    setPassword("");
  }, []);

  const onRemovePress = useCallback(() => {
    setPassword((prev) => prev.substring(0, prev.length - 1));
  }, []);

  useEffect(() => {
    if (groupDiscounts) {
      setOrder(prev => UpdateGroupDiscount(prev, kiosk, groupDiscounts))
    }
  }, [groupDiscounts, kiosk])
  

  useEffect(() => {
    if (password.length === 4) {
      if (cardData?.holderDocument?.substring(0, 4) === password) {
        setVisible(false);
        disableSensor();
        deffered.current.resolver({cardData, order});
      } else {
        toast("Digitos não correspondem", "error");
        setConfirmarSenhaOpen(false);
      }
    }
  }, [cardData, order, password, toast, disableSensor]);

  useEffect(() => {
    if (!confirmarSenhaOpen) {
      setPassword("");
    }
  }, [confirmarSenhaOpen]);

  const onClickConfirmarCashless = useCallback(() => {
    if (
      (!kiosk?.requestDocumentOnConsumeCashless || !cardData?.holderDocument) ||
      kiosk?.localId === "a70d41ca-ff5e-29f5-22f4-4dec740defc2"
    ) {
      if (cardData) {
        setConfirmarSenhaOpen(false);
        setVisible(false);
        disableSensor();
        deffered.current.resolver({cardData, order});
      }
    } else {
      setConfirmarSenhaOpen(true);
    }
  }, [cardData, kiosk, order, disableSensor]);

  useEffect(() => {
    if (posPaid && cardData) {
      if (!cardData.group) {
        onClickConfirmarCashless();
      }
    }
  }, [cardData, onClickConfirmarCashless, posPaid]);

  useEffect(() => {
    if (posPaid && cardData && order.groupDiscountOrder) {
      onClickConfirmarCashless();
    }
  }, [cardData, onClickConfirmarCashless, order.groupDiscountOrder, posPaid]);

  const onCancel = useCallback(() => {
    setVisible(false);
    disableSensor();
    deffered.current?.rejecter(new Error("Fechado pelo usuário ou tempo esgotado"));
  }, [disableSensor]);

  return (
    <Modal open={visible} id={styles.Cashless}>
      <div className={styles.cashlessModal}>
        <div className={styles.container}>
          <div className={styles.topLabel}>CONFIRMAR PEDIDO</div>
          <IconButton
            onClick={onCancel}
            className={styles.closeIcon}
          >
            <Icon fontSize="large">close</Icon>
          </IconButton>
        </div>
        {!cardData ? (
          <div className={styles.content}>
            <Icon fontSize="large">nfc</Icon>
            <div>Aproxime seu cartão/pulseira do leitor</div>
          </div>
        ) : (
          <div>
            {cardData.holderName && (
              <div className={styles.paymentCashless}>
                <div>Nome:</div>
                <div className={styles.paymentCashlessValue}>
                  {cardData.holderName}
                </div>
              </div>
            )}
            {!posPaid && (
              <div className={styles.paymentCashless}>
                <div>
                  Saldo do Cartão/Pulseira:
                </div>
                <div className={styles.paymentCashlessValue}>
                  R${cardData.balance.toFixed(2).replace(".", ",")}
                </div>
              </div>
            )}
            {(order.totalWithoutDiscount && !posPaid) && (
              <>
                <div className={styles.paymentCashless}>
                  <div>Total:</div>
                  <div className={styles.paymentCashlessValue}>
                    R${(order.totalWithoutDiscount ?? 0).toFixed(2).replace(".", ",")}
                  </div>
                </div>
                <div className={styles.paymentCashless}>
                  <div>Desconto de etiqueta:</div>
                  <div className={styles.paymentCashlessValue}>
                    - R${(order.totalWithoutDiscount - order.totalValue).toFixed(2).replace(".", ",")}
                  </div>
                </div>
              </>
            )}
            <div className={styles.paymentCashless}>
              <div>Total a Pagar:</div>
              <div className={styles.paymentCashlessValue}>
                R${(order.totalValue ?? 0).toFixed(2).replace(".", ",")}
              </div>
            </div>
            {cardData.balance < order.totalValue && !posPaid && (
              <div className={styles.paymentCashless}>
                <div>
                  Saldo insuficiente para efetuar pagamento.
                </div>
              </div>
            )}
            <div className={styles.buttonsContainer}>
              <div
                onClick={onCancel}
                style={{ marginRight: 6 }}
                className={styles.cancel}
              >
                Cancelar
              </div>
              {(cardData.balance >= order.totalValue || posPaid) && (
                <div
                  onClick={onClickConfirmarCashless}
                  style={{ marginLeft: 6 }}
                  className={styles.confirm}
                >
                  Confirmar
                </div>
              )}
            </div>
            <Drawer
              anchor="bottom"
              open={confirmarSenhaOpen}
              onClose={() => setConfirmarSenhaOpen(false)}
            >
              <div className={"drawer-paymentCashless"}>
                <h2>Digite os 4 primeiros digitos do seu CPF</h2>
                <div className={"password-paymentCashless"}>
                  {password.replace(/[0-9]/g, "*")}
                </div>
                <TecladoVirtual
                  onKeyPress={onKeyPress}
                  onPressClear={onClearPress}
                  onPressBack={onRemovePress}
                  type="clear"
                />
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(UseCashless);
