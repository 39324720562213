import { IBarCodeRepository } from "../../interfaces/IBarCodeRepository";
import { ICatalogLocalStorageRepository } from "../../interfaces/ICatalogLocalStorageRepository";
import { CatalogLocalStorageRepository } from "./CatalogLocalStorageRepository";

export const BarCodeRepository = (
): IBarCodeRepository => {
  const catalogLocalStorage: ICatalogLocalStorageRepository = CatalogLocalStorageRepository()
  const getProduct = async (barCode: string) => {
    console.log("barcode:", barCode);


    try {

      const catalog = await catalogLocalStorage.get();
      const products = catalog?.stores.flatMap((item) => item.seccionedProduct).flatMap(item => item.products)

      let productSelected_ = products?.find((item) => {
        const barCodeComplete = item.barCode?.padEnd(6, '0')

        return (barCodeComplete === barCode)
      })

      return productSelected_ ?? null
    } catch (error) {
      console.error(error)
      return null
    }

  };

  return { getProduct };
};
