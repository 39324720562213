import {
  IClient,
  IOrderData,
  IProductType,
  OrderStatusEnum,
} from "components/layoutsPrinter/IOrderData";
import {
  IPrintOrder,
  PrintOrderType,
} from "components/layoutsPrinter/IPrintOrder";
import { ITicketPrintOrderData } from "components/layoutsPrinter/ITicketPrintOrderData";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { v4 } from "uuid";

export const CreatePrintOrderUseCase = (
  pedidoPOS: IPedidoPosDTO,
  kiosk: Kiosk,
  ticketId?: string
): IPrintOrder[] => {
  let ordersToPrint: IPrintOrder[] = [];

  if (kiosk.printType === PrintType.TICKET) {
    if (ticketId) {
      const ticketIndex = pedidoPOS.produtosObject.findIndex(
        (it) => it.ticketId === ticketId
      );

      if (ticketIndex > -1) {
        const ticket = pedidoPOS.produtosObject[ticketIndex];
        const orderData: ITicketPrintOrderData = {
          total: pedidoPOS.valorTotal,
          orderCode: pedidoPOS.codigoPedido,
          client: pedidoPOS.clienteEstabelecimentoObject ?? ({} as IClient),
          paymentType: pedidoPOS.dadosTransacaoObject?.TipoPagamento,
          pedidoPOSId: pedidoPOS.pedidoPOSId,
          pedidoPOSPaiId: pedidoPOS.pedidoPOSPaiId,
          orderStatus: OrderStatusEnum.Aprovado,
          orderDate: pedidoPOS.dataPedido,
          friendlyId:
            kiosk?.kioskName.replace(/\D/gm, "") +
            " " +
            ticket.id?.substring(0, 4),
          products: pedidoPOS.produtosObject.map((i) => ({
            id: i.produtoId,
            name: i.nomeProduto,
            ticketId: i.ticketId,
            storeName: i.nomeLoja,
            category: i.category,
            quantity: i.quantidade,
            value: i.valor,
            observations: [],
            type: i.tipoProduto as unknown as IProductType,
            printerName: i.impressora,
          })),
          product: {
            id: ticket.produtoId,
            name: ticket.nomeProduto,
            ticketId: ticket.ticketId,
            storeName: ticket.nomeLoja,
            category: ticket.category,
            quantity: ticket.quantidade,
            type: ticket.tipoProduto as unknown as IProductType,
            value: ticket.valor,
            printerName: ticket.impressora,
            observations: ticket.opcoesDoConsumidorObject.map((j) => ({
              id: j.id,
              description: j.descricao,
              options: j.opcoes.map((k) => ({
                id: k.id,
                productId: k.produtoId,
                description: k.descricao,
                isChecked: k.isChecked,
                quantity: k.quantidade,
                productValue: k.produtoValor,
                quantityFree: k.quantidadeGratuito,
                currentFree: k.quantidadeGratuitoSelecionado,
              })),
            })),
          },
          acquirerType: pedidoPOS.tipoAdquirente,
          transaction: pedidoPOS.dadosTransacaoObject,
          tag: pedidoPOS.clienteEstabelecimentoObject.tag ?? "",
          orderObservation: pedidoPOS.observacaoPedido,
          orderPassword: pedidoPOS.codigoPainelSenha,
          index: ticketIndex,
          isReprint: true,
        };

        const printOrder: IPrintOrder = {
          createdAt: pedidoPOS.dataPedido ?? new Date(),
          id: v4(),
          orderId: pedidoPOS.pedidoPOSId,
          submitedAt: new Date(),
          ticketId: ticket.id,
          data: orderData,
          type: PrintOrderType.ORDER,
          printType: PrintType.TICKET,
        };

        ordersToPrint.push(printOrder);
      }
    } else {
      pedidoPOS.produtosObject
        .filter((i) => !i.nomeProduto.includes("Taxa de serviço"))
        .forEach((it, index) => {
          const orderData: ITicketPrintOrderData = {
            total: pedidoPOS.valorTotal,
            orderCode: pedidoPOS.codigoPedido,
            client: pedidoPOS.clienteEstabelecimentoObject ?? ({} as IClient),
            paymentType: pedidoPOS.dadosTransacaoObject?.TipoPagamento,
            pedidoPOSId: pedidoPOS.pedidoPOSId,
            pedidoPOSPaiId: pedidoPOS.pedidoPOSPaiId,
            orderStatus: OrderStatusEnum.Aprovado,
            orderDate: pedidoPOS.dataPedido,
            friendlyId:
              kiosk?.kioskName.replace(/\D/gm, "") +
              " " +
              it.id?.substring(0, 4),
            products: pedidoPOS.produtosObject.map((i) => ({
              id: i.produtoId,
              name: i.nomeProduto,
              ticketId: i.ticketId,
              storeName: i.nomeLoja,
              category: i.category,
              quantity: i.quantidade,
              value: i.valor,
              observations: [],
              printerName: i.impressora,
              type: i.tipoProduto as unknown as IProductType,
            })),
            product: {
              id: it.produtoId,
              name: it.nomeProduto,
              ticketId: it.ticketId,
              storeName: it.nomeLoja,
              category: it.category,
              quantity: it.quantidade,
              type: it.tipoProduto as unknown as IProductType,
              value: it.valor,
              printerName: it.impressora,
              observations: it.opcoesDoConsumidorObject.map((j) => ({
                id: j.id,
                description: j.descricao,
                options: j.opcoes.map((k) => ({
                  id: k.id,
                  productId: k.produtoId,
                  description: k.descricao,
                  isChecked: k.isChecked,
                  quantity: k.quantidade,
                  productValue: k.produtoValor,
                  quantityFree: k.quantidadeGratuito,
                  currentFree: k.quantidadeGratuitoSelecionado,
                })),
              })),
            },
            acquirerType: pedidoPOS.tipoAdquirente,
            transaction: pedidoPOS.dadosTransacaoObject,
            tag: pedidoPOS.clienteEstabelecimentoObject.tag ?? "",
            orderObservation: pedidoPOS.observacaoPedido,
            orderPassword: pedidoPOS.codigoPainelSenha,
            index: index,
            isReprint: false,
          };

          const printOrder: IPrintOrder = {
            createdAt: pedidoPOS.dataPedido ?? new Date(),
            id: v4(),
            orderId: pedidoPOS.pedidoPOSId,
            submitedAt: new Date(),
            ticketId: it.id,
            data: orderData,
            type: PrintOrderType.ORDER,
            printType: PrintType.TICKET,
          };

          ordersToPrint.push(printOrder);
        });
    }
  } else if (kiosk.printType === PrintType.RESUME) {
    const orderData: IOrderData = {
      total: pedidoPOS.valorTotal,
      orderCode: pedidoPOS.codigoPedido,
      client: pedidoPOS.clienteEstabelecimentoObject ?? ({} as IClient),
      paymentType: pedidoPOS.dadosTransacaoObject?.TipoPagamento,
      pedidoPOSId: pedidoPOS.pedidoPOSId,
      pedidoPOSPaiId: pedidoPOS.pedidoPOSPaiId,
      orderStatus: OrderStatusEnum.Aprovado,
      orderDate: pedidoPOS.dataPedido,
      friendlyId:
        kiosk?.kioskName.replace(/\D/gm, "") +
        " " +
        pedidoPOS?.pedidoPOSId?.substring(0, 4),
      products: pedidoPOS.produtosObject.map((i) => ({
        id: i.produtoId,
        name: i.nomeProduto,
        ticketId: i.ticketId,
        storeName: i.nomeLoja,
        category: i.category,
        quantity: i.quantidade,
        value: i.valor,
        printerName: i.impressora,
        type: i.tipoProduto as unknown as IProductType,
        observations: i.opcoesDoConsumidorObject.map((j) => ({
          id: j.id,
          description: j.descricao,
          options: j.opcoes.map((k) => ({
            id: k.id,
            productId: k.produtoId,
            description: k.descricao,
            isChecked: k.isChecked,
            quantity: k.quantidade,
            productValue: k.produtoValor,
            quantityFree: k.quantidadeGratuito,
            currentFree: k.quantidadeGratuitoSelecionado,
          })),
        })),
      })),
      acquirerType: pedidoPOS.tipoAdquirente,
      transaction: pedidoPOS.dadosTransacaoObject,
      tag: pedidoPOS.clienteEstabelecimentoObject.tag ?? "",
      orderObservation: pedidoPOS.observacaoPedido,
      orderPassword: pedidoPOS.codigoPainelSenha,
    };

    const printOrder: IPrintOrder = {
      createdAt: pedidoPOS.dataPedido ?? new Date(),
      id: v4(),
      orderId: pedidoPOS.pedidoPOSId,
      submitedAt: new Date(),
      data: orderData,
      type: PrintOrderType.ORDER,
      printType: PrintType.RESUME,
    };

    ordersToPrint.push(printOrder);
  }

  return ordersToPrint;
};
