import React, { FC } from 'react'
import { OrderItem } from '../../../../../domains/order/agreggates/order/OrderItem'
import { Grid, IconButton, Icon } from '@material-ui/core';
import "./OrderItemPreview.css"
interface OrderItemPreviewProps {
    orderItem?: OrderItem;
    indice?: boolean

    onClickPrint?: () => void,
    permissionPrint?: boolean
    isTicketPrinter?: boolean
    isPayd?: boolean
}
export const OrderItemPreview: FC<OrderItemPreviewProps> = ({ orderItem, indice, onClickPrint, permissionPrint, isTicketPrinter, isPayd }) => {


    return (
        <Grid container sm={12} item className="content-orderItemPreview">
            <Grid sm={6} item className="nome-orderItemPreview">
                {
                    indice ?
                        "Nome"
                        : orderItem?.name

                }
            </Grid>
            <Grid sm={1} item className="quantidade-orderItemPreview">
                {
                    indice ?
                        "Qt"
                        : orderItem?.quantity

                }
            </Grid>
            <Grid sm={2} item className="total-orderItemPreview">
                {
                    indice ?
                        "Preço"
                        : "R$" + orderItem?.price.toFixed(2).replace(".", ",")

                }
            </Grid>
            <Grid sm={1} item className="actions-orderItemPreview">
                {
                    indice ?
                        "Ações"
                        :

                        isPayd && permissionPrint && isTicketPrinter && orderItem?.name !== "Pagamento"  && < IconButton onClick={onClickPrint}><Icon>print</Icon></IconButton>
                }
            </Grid>
        </Grid >
    )
}
