import { TransactionType } from "domains/order/agreggates/payment/transactionType";
import { IFinancialTransactionModel } from "modules/payment/domain/models/FinancialTransactionModel";

export interface IClient {
  name?: string;
  identificator?: string;
  document?: string;
  phone?: string;
  email?: string;
  command?: string;
  table?: string;
  pedidoPosPaiId?: string;
  pedidoPosId?: string;
  tag?: string;
}

export enum OrderStatusEnum {
  Pendente = 1,
  Falha = 2,
  Aprovado = 3,
  Cancelado = 4,
  ParcialmenteCancelado = 5,
  AguardandoPagamento = 6,
}

export enum IProductType {
  Produto = 1,
  Recarga = 2,
  Variavel = 3,
  Promocao = 4,

  AtivacaoCashless = 5,
  DesativacaoCashless = 6,

  AtivacaoCashlessPosPago = 7,
  DesativacaoCashlessPosPago = 8,

  AtivacaoCashlessComanda = 9,
  DesativacaoCashlessComanda = 10,
  TaxaServico = 11,
  Kilo = 12,
  Ajuste = 13,
  TaxaEntrega = 14,
  Agendamento = 50,
}

export interface IOptions {
  id: string;
  productId?: string;
  description: string;
  isChecked: boolean;
  quantity: number;
  productValue: number;
  quantityFree: number;
  currentFree: number;
  isProductGeneralObservation?: boolean;
}

export interface IObservations {
  id: string;
  description: string;
  options: IOptions[];
}

export interface IOrderProduct {
  id: string;
  name: string;
  ticketId: string;
  storeName?: string;
  category?: string;
  quantity?: number | null;
  type?: IProductType;
  value: number;
  observations: IObservations[];
  printerName: string | null;
}

export interface IOrderData {
  total: number;
  orderCode: string;
  client: IClient;
  paymentType?: TransactionType;
  pedidoPOSId?: string;
  pedidoPOSPaiId?: string;
  orderStatus?: OrderStatusEnum;
  orderDate?: Date;
  products: IOrderProduct[];
  acquirerType: number;
  transaction?: IFinancialTransactionModel;
  tag: string;
  orderObservation?: string;
  orderPassword?: string;
  isDataRecorded?: boolean | null;
  isOfflineRecharge?: boolean;
  isOfflineRechargeRecordConfirmed?: boolean;
  friendlyId: string;
}
