
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";
import { ICatalogRepository } from "../../../domains/catalog/interfaces/repositories/ICatalogRepository";
import { Catalog, lastSyncCatalogResponse } from "../../../domains/catalog/aggregates/Catalog";
import { LocalStorageRepository } from "./_LocalStorageRepository";
import { HttpRepository } from "./_HttpRepository";

const key = "CATALOG";
const keyLastSync = "lastSync";
export const CatalogRepository = (
): ICatalogRepository => {
  const localStorageRepository: ILocalStorageRepository = LocalStorageRepository();
  const httpRepository: IHttpRepository = HttpRepository();
  const getAsync = async () => {
    try {
      const catalog = await localStorageRepository.get<Catalog>(key);
      if (catalog) {
        return catalog;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  const syncAsync = async () => {
    let response = await httpRepository.getAsync<Catalog>("/equipment/productcatalog")

    console.log(response);

    localStorageRepository.remove(key);
    localStorageRepository.post(key, response);

    return response;
  };

  const _lastSyncAsync = async (localId: string) => {
    let response = await httpRepository.getAsync<{ ultimaAtualizacaoCardapio: string }>(
      "/Produto/BuscarUltimaAlteracaoProdutos/" + localId,
      { timeout: 15000 }
    )


    const newlastSyncCatalog: lastSyncCatalogResponse = {
      lastSyncCatalog: response?.ultimaAtualizacaoCardapio ?? "",
    };

    return newlastSyncCatalog;
  };

  const syncIfDiffAsync = async (localId: string) => {
    const lastSyncCatalogResponse = await _lastSyncAsync(localId);   

    const lastSyncStorage = await localStorageRepository.get<lastSyncCatalogResponse>(keyLastSync)


    console.log(lastSyncCatalogResponse, lastSyncStorage);

    if (lastSyncCatalogResponse.lastSyncCatalog !== lastSyncStorage?.lastSyncCatalog) {

      if (lastSyncCatalogResponse) {
        localStorageRepository.remove(keyLastSync);
        localStorageRepository.post(
          keyLastSync,
          lastSyncCatalogResponse
        );
      }

      return true;
    } else {
      return false;
    }
  }

  return {   getAsync, syncAsync, syncIfDiffAsync };
};
