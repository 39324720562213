import { useWeighingMachine } from 'application/contexts/weighingMachine/WeighingMachine';
import { BotaoVoltar } from 'components/botaoVoltar/BotaoVoltar';
import React, { FC, useEffect } from 'react'
import { BarCode } from "../../areas/barCode/BarCode";

export interface IWeighingMachinePageProps {
}
const WeighingMachinePage: FC<IWeighingMachinePageProps> = () => {
    const { onPressBack, enableWeighModal, disableWeighModal } = useWeighingMachine();

    useEffect(() => {
        enableWeighModal()
        return ()=>{
            console.log('closeWeightPage')
            disableWeighModal();
        }
    }, [disableWeighModal, enableWeighModal])

    return (
        <div  >
            <BotaoVoltar onClick={onPressBack} />
            <BarCode weighing />
        </div>
    )
}
export default WeighingMachinePage