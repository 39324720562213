import React, { useEffect } from "react";
import { useCallback, useImperativeHandle, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { IPrintOrder, PrintOrderType } from "./IPrintOrder";
import {
  createDefferedPromise,
  ICreateDefferedPromise,
} from "utils/DefferedPromise";
import { GetError } from "utils/GetError";
import { Resume } from "./resumoLayoutPrinter/Resume";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import ResizeObserver from "resize-observer-polyfill";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { Ticket } from "./ticketLayoutPrinter/Ticket";
import CpfRegister from "./cpfRegisterLayoutPrinter/CpfRegister";
import { Remote } from "./remotePrinter/Remote";
import { Nfce } from "./invoiceLayoutPrinter/Nfce";
import { OfflineRecharge } from "./offlineRechargeLayout/OfflineRecharge";
import { WalletReceipt } from "./walletReceiptLayout/WalletReceipt";
import { WalletRecharge } from "./walletRechargeLayout/WalletRecharge";
// import debounce from "lodash/debounce";

export interface ILayoutPrinterProps {}

export interface ILayoutPrinterHandler {
  getPrintLayout(data: IPrintOrder): Promise<string>;
}

const LayoutPrinter: React.ForwardRefRenderFunction<
  ILayoutPrinterHandler,
  ILayoutPrinterProps
> = (_props, ref) => {
  const { kiosk } = useKiosk();
  const deffered = useRef<ICreateDefferedPromise<any>>(createDefferedPromise());

  const [printOrder, setPrintOrder] = useState<IPrintOrder | null>(null);
  const layoutRef = useRef<HTMLDivElement | null>(null);

  const capture = useCallback(async () => {
    if (!layoutRef.current) {
      console.warn("LayoutPrinter: layoutRef.current is null");
      return;
    }

    try {
      console.log("LayoutPrinter [capture]: Starting capture");
      const canvas: HTMLCanvasElement = await html2canvas(layoutRef.current, {
        scale: 1,
        scrollY: 0,
      });

      const base64image: string = canvas.toDataURL("image/png", 1);
      console.log("[SUCCESS] LayoutPrinter: Image generated");

      deffered.current.resolver(base64image);
      setPrintOrder(null);
    } catch (error) {
      deffered.current?.rejecter(GetError(error, "capture", "LayoutPrinter"));
    }
  }, []);

  const getPrintLayout = useCallback(async (_data: IPrintOrder) => {
    if (_data.type === PrintOrderType.PINPAD_RECEIPT) {
      return;
    }
    console.log("[LAYOUT_PRINTER]", _data);
    setPrintOrder(_data);
    deffered.current = createDefferedPromise();
    return deffered.current.promise;
  }, []);

  useImperativeHandle(ref, () => ({ getPrintLayout }));

  const onLayout = useCallback(
    () => {
      console.log("LayoutPrinter [onLayout]", new Date().toISOString());
      capture();
    },
    [capture]
  );

  useEffect(() => {
    const element = layoutRef.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      onLayout();
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [onLayout]);

  const renderLayout = () => {
    if (!printOrder || !kiosk) return null;

    switch (printOrder.type) {
      case PrintOrderType.ORDER:
        switch (printOrder.printType) {
          case PrintType.RESUME:
            return <Resume kiosk={kiosk} order={printOrder.data}/>;
          case PrintType.GROUPED_RESUME:
            return <></>;
          case PrintType.TICKET:
          case PrintType.SIMPLE_RESUME:
            return <Ticket kiosk={kiosk} order={printOrder.data}></Ticket>;
          default:
            return null;
        }
      case PrintOrderType.CPF_REGISTER:
        return <CpfRegister order={printOrder.data} />;
      case PrintOrderType.REMOTE_PRINT:
        return <Remote kiosk={kiosk} order={printOrder.data} />;
      case PrintOrderType.NFCE:
        return <Nfce kiosk={kiosk} order={printOrder.data}/>;
      case PrintOrderType.OFFLINE_RECHARGE:
        return <OfflineRecharge kiosk={kiosk} order={printOrder.data}/>;
      case PrintOrderType.WALLET_RECEIPT:
        return <WalletReceipt kiosk={kiosk} order={printOrder.data}/>;
      case PrintOrderType.WALLET_RECHARGE:
        return <WalletRecharge kiosk={kiosk} order={printOrder.data}/>;
      default:
        return null;
    }
  };

  return (
    <div
      ref={layoutRef}
      style={{
        bottom: -1000,
        left: -50000,
        zIndex: -5000000,
        position: "fixed",
        overflow: "visible",
        minWidth: 300,
      }}
    >
      {renderLayout()}
    </div>
  );
};

export default React.forwardRef(LayoutPrinter);
