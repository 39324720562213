import { Icon } from "@material-ui/core";
import React, { FC } from "react";
import { CSSTransition } from "react-transition-group";
import BotaoInicio from "../inicio/components/botaoInicio/BotaoInicio";
import { PapelDeParedePlayer } from "../inicio/components/papelDeParedePlayer/PapelDeParedePlayer";
import "./BarCode.css";
import { UseBarCode } from "./hooks/UseBarCode";


interface IBarCodePageProps {
  weighing?: boolean
}

export const BarCode: FC<IBarCodePageProps> = ({ weighing }) => {

  const {onClickComprarManualmente, refDiv, kiosk, focus, alert} = UseBarCode(weighing);

  return (
    <div ref={refDiv} onClick={focus} className="container-barcode">
      <PapelDeParedePlayer weighing={weighing}></PapelDeParedePlayer>
      {!kiosk?.barCodeMode && (
        <div className="botao-comprar-manualmente">
          <BotaoInicio
            title="Fazer pedido - Escolher outros produtos"
            onClick={onClickComprarManualmente}
          ></BotaoInicio>
        </div>
      )}
      <CSSTransition
        in={Boolean(alert)}
        classNames={"alert-toast"}
        timeout={300}
        unmountOnExit
        mountOnEnter
      >
        <div className="alert-barcode">
          {" "}
          <Icon fontSize="large">alert</Icon> Produto Não Cadastrado
        </div>
      </CSSTransition>
    </div>
  );
};
