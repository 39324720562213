import { IOrderProduct } from "components/layoutsPrinter/IOrderData";
import { IPrintOrder } from "components/layoutsPrinter/IPrintOrder";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { OrderResume } from "domains/order/agreggates/order/OrderResume";
import { IPrintSector } from "Infrastructure/repositories/api/ConfigTotemMeep";
import moment from "moment";

export const parsePrintResume = (
  printOrder: IPrintOrder,
  kiosk: Kiosk,
  printFile: string | null
) => {
  const resume: OrderResume = {
    id: printOrder.data.friendlyId,
    businessName: kiosk?.localName ?? "",
    customer:
      (printOrder.data.client?.name ?? "--") +
      (printOrder.data.orderObservation ?? ""),
    tag: printOrder.data.client?.tag ?? "",
    responsable: "Totem",
    pointOfSale: kiosk?.kioskName ?? "TOTEM",
    date: moment.utc().toDate(),
    isCanceled: false,
    canceledBy: null,
    totalValue: printOrder.data.total,
    details: printOrder.data.products.map((item: IOrderProduct) => ({
      quantity: item.quantity ?? 1,
      productName: item.name,
      productCategory: item.category ?? "",
      value: item.value,
      totalValue: item.value * (item.quantity ?? 1),
      notes: item.observations?.map((obs) => ({
        description: obs.description,
        options: obs.options.map((op) => op.quantity + "x -" + op.description),
      })),
      orderNumber: item.id,
      printerName:
        findPrinterNameByProductId(
          kiosk?.printingSectors ?? [],
          item.id,
          kiosk?.id ?? ""
        ) ??
        item.printerName ??
        "",
      attendantName: null,
      operatorName: null,
    })),
    orderFriendlyId:
      printOrder.data.friendlyId +
      " -" +
      (printOrder.data.orderObservation
        ? "(" + printOrder.data.orderObservation + ")"
        : "") +
      (printOrder.data.client?.table
        ? "(mesa:" + printOrder.data.client.table + ")"
        : ""),
    prismNumber: printOrder.data.client?.table ?? "",
    table: printOrder.data.client?.table ?? "",
    note: printOrder.data.orderObservation ?? "",
    imageBase64: printFile ?? "",
    duplicatePrint: kiosk?.duplicateRemotePrint,
  };

  return resume;
};

const findPrinterNameByProductId = (
  printingSectorsContainer: IPrintSector[],
  productId: string,
  totemId: string
) => {
  let printerName;
  printingSectorsContainer.forEach((it) => {
    if (it.productsIds.includes(productId) && it.totemsIds.includes(totemId)) {
      printerName = it.printerName;
    }
  });
  return printerName;
};
