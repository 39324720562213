import { ErrorCodeType } from "application/models/IError";
import {
  PrintOrderType,
  SpoolerStatus,
} from "components/layoutsPrinter/IPrintOrder";
import LayoutPrinter, {
  ILayoutPrinterHandler,
} from "components/layoutsPrinter/LayoutPrinter";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { PrintType } from "domains/kiosk/aggregates/PrintType";
import { PrintStatus } from "domains/order/agreggates/order/Order";
import { IPrintRepository } from "domains/order/interfaces/repositories/iprintRespository";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { LocalPrintRepository } from "Infrastructure/services/cefSharpService/local/_LocalPrintRepository";
import { IOrderLog } from "modules/order/domain/models/IOrderLog";
import { localPrintUseCase } from "modules/print/application/useCases/LocalPrintUseCase";
import React, { createRef, FC, useCallback, useEffect } from "react";
import { GetError } from "utils/GetError";
import { useShallow } from "zustand/react/shallow";
import { PrinterStore, usePrinterStore } from "./PrinterStore";

const locaStorageOrderRepository = OrderRepositoryLocalStorage();

export interface IPrinterProviderProps {
  kiosk: Kiosk | null;
}

export const layoutPrinterRef = createRef<ILayoutPrinterHandler>();
const NewPrinterProvider: FC<IPrinterProviderProps> = ({ kiosk }) => {
  const printRepository: IPrintRepository = LocalPrintRepository();
  const { addLog } = useAppInsights();
  const [
    setSpoolerStatus,
    spoolerStatus,
    removeOrder,
    getOrderToPrint,
    ordersToPrint,
  ] = usePrinterStore(
    useShallow((state: PrinterStore) => [
      state.setSpoolerStatus,
      state.spoolerStatus,
      state.removeOrder,
      state.getOrderToPrint,
      state.ordersToPrint,
    ])
  );

  const addOrderLog = useCallback(
    async (
      order: IOrderLog | null,
      message: string,
      printStatus: PrintStatus
    ) => {
      try {
        if (order) {
          let orderLog = { ...order, printStatus: PrintStatus[printStatus] };
          console.log({ orderLog, message });
          addLog("order", { message, orderLog });
          await locaStorageOrderRepository.add(
            orderLog,
            message,
            undefined,
            printStatus
          );
        }
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "NewPrinterProvider",
          "Falha ao adicionar log",
          ErrorCodeType.PRINT
        );
      }
    },
    [addLog]
  );

  const printOrderHandler = useCallback(
    async (_kiosk: Kiosk) => {
      const orderToPrint = getOrderToPrint();
      try {
        console.log("[PRINTER_PROVIDER][START]", {
          type: PrintOrderType[orderToPrint.type],
          printType: PrintType[orderToPrint.printType],
          orderToPrint,
        });
        await localPrintUseCase(
          layoutPrinterRef,
          printRepository,
          orderToPrint,
          _kiosk
        );
        if (orderToPrint.type === PrintOrderType.ORDER) {
          await addOrderLog(
            {
              id: orderToPrint.orderId,
              ticketId: orderToPrint.data?.product?.ticketId,
            } as IOrderLog,
            `${
              orderToPrint.data?.isReprint
                ? "Reimpressão completa"
                : "Impressão completa"
            } ${
              orderToPrint.data?.product?.ticketId
                ? `: Ticket ${orderToPrint.data.index + 1}`
                : ""
            }`,
            PrintStatus.SUCCESS
          );
        }
        console.log("[PRINTER_PROVIDER][FINISH]", { printed: orderToPrint.id });
        removeOrder(orderToPrint.id);
        setSpoolerStatus(SpoolerStatus.standby);
      } catch (error) {
        if (orderToPrint.type === PrintOrderType.ORDER) {
          await addOrderLog(
            {
              id: orderToPrint.orderId,
              ticketId: orderToPrint.data?.product?.ticketId,
            } as IOrderLog,
            `Falha na impressão ${
              orderToPrint.data?.product?.ticketId
                ? `: Ticket ${orderToPrint.data.index + 1}`
                : ""
            }`,
            PrintStatus.ERROR
          );
        }
        removeOrder(orderToPrint.id);
        setSpoolerStatus(SpoolerStatus.standby);
        console.log("[PRINTER_PROVIDER][ERROR]", { error });
      }
    },
    [
      addOrderLog,
      getOrderToPrint,
      printRepository,
      removeOrder,
      setSpoolerStatus,
    ]
  );

  useEffect(() => {
    console.log("[PRINTER_PROVIDER][useEffect]", {
      ordersToPrint,
      spoolerStatus: SpoolerStatus[spoolerStatus],
    });
    if (
      ordersToPrint.length > 0 &&
      spoolerStatus === SpoolerStatus.standby &&
      kiosk &&
      !kiosk.enableBarCode // Barcode não existe impressão TODO: Tratar com parâmetro
    ) {
      setSpoolerStatus(SpoolerStatus.isPrinting);
      printOrderHandler(kiosk);
    }
  }, [
    kiosk,
    ordersToPrint,
    printOrderHandler,
    printRepository,
    setSpoolerStatus,
    spoolerStatus,
  ]);

  if (!ordersToPrint || ordersToPrint.length === 0) {
    return null;
  }

  return (
    <>
      {/* <LayoutPrinterViewer ref={layoutViewerRef} /> */}
      <LayoutPrinter ref={layoutPrinterRef} />
    </>
  );
};

export default NewPrinterProvider;
