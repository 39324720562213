import { IWalletRechargeModel } from 'modules/payment/domain/models/IWalletTransaction';
import { INfce } from './INfce';
import { IOrder, IOrderItem } from './IOrder';
import { IPedidoPosDTO } from './IPedidoPosDTO';
import { ProdutoPedidoPosDTO } from './ProdutoPedidoPosDTO';
import { PrintStatus } from 'domains/order/agreggates/order/Order';


export interface IOrderLog extends IOrder {
  log?: ILog[];
  isSynchronized?: boolean;
  pedidoPOS?: IPedidoPosOrderLog;
  orderItems: OrderLogItems[];
  offlineRechargeStatus?: IOfflineRechargeStatus;
  paymentApproved?: boolean;
  // reprinted?: RePrintOrderSyncObject 
  nfce?: INfce[];
  pixLog?: IPixLog[];
  walletRechargeModel?: IWalletRechargeModel;
  printStatus?: PrintStatus;
  ticketId?: string;
}

export interface IPixLog {
  time: string;
  message: string;
  pix: string;
}

export enum IOfflineRechargeStatus {
  PAYMENT = 1,
  RECHARGE,
  COMPLETED
}

export interface ILog {
  time: string;
  message: string;
}

export interface OrderLogItems extends IOrderItem {
  printStatus?: PrintStatus;
}

export interface IPedidoPosOrderLog extends IPedidoPosDTO {
  produtosObject: ProdutoPedidoPosOrderLog[];
  reprinted?: RePrintOrderSyncObject[];
}

export interface ProdutoPedidoPosOrderLog extends ProdutoPedidoPosDTO {
  reprinted?: RePrintOrderSyncObject[];
}

export interface RePrintOrderSyncObject {
  reimpressaoId: string;
  dataRealizacao: Date;
  ticketId: string;
  operadorId: string;
  localClienteId: string;
  equipamentoId: string;
}

